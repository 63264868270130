import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, FormControlName} from '@angular/forms';
import { AuthServiceService } from '../../../services/auth/auth-service.service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-login-user',
  templateUrl: './login-user.component.html',
  styleUrls: ['./login-user.component.scss'],
  
})
export class LoginUserComponent implements OnInit {

	loginFormGroup : FormGroup;
  invalidUser : boolean = false;
  loading : boolean = false;

  constructor(
  	public _formBuilder : FormBuilder,
    public authService  : AuthServiceService,
    private router      : Router,
    public snack : MatSnackBar,
    private cookieService: CookieService
  	) {   

    }

  ngOnInit() {

 	this.loginFormGroup = this._formBuilder.group({
  		email   : new FormControl('', Validators.required),
  		password      : new FormControl('', Validators.required),
  	});
  }

  login(){
    this.loading = true;
    this.authService.login(this.loginFormGroup.value)
    .subscribe(res => {
      localStorage.setItem("token", res["success"].token);
      this.cookieService.set('token', res["success"].token);
      localStorage.setItem("user",  JSON.stringify(res["usuario"]));
      localStorage.setItem("permissions", JSON.stringify(res["permissions"]));
      localStorage.setItem("sign", JSON.stringify(res["sign"]));
      localStorage.setItem("seal", JSON.stringify(res["seal"]));
      this.router.navigate(['/']);
      this.loading = false;
    }, error => {
      this.loading = false;
      switch (error) {
        case "Unknown Error":
          this.snack.open('Verifique su conexión a internet.', 'ok', { duration : 2500 });          
          break;
        case "Not Found" : 
          this.snack.open('Usuario o Contraseña incorrecta.', 'ok', { duration : 2500 });
          break;
        default:
          this.snack.open('Ha ocurrido un error.', 'ok', { duration : 2500 });          
          break;
      }
    });
  
  }

}
