import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MailService } from '../../../services/mail/mail.service';
import { MatSnackBar } from '@angular/material';
import { MailInfoComponent } from '../mail-info/mail-info.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})

export class HomeComponent implements OnInit {

  public type: string;
  public mails: any = [];
  public cpm: any = [];
  public loading = false;
  public selected: any = [];
  public page = 1;
  public pageSize = 6;
  public pageSizeOpts: number[] = [6, 10, 15];
  public tabs: any[];
  public nr = 0;
  public nrc: any;
  public isSelected: boolean;
  public trayCan: trayConfig;
  private sub: any;
  private currentTab = 'ALL';


  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private mail: MailService,
    public snack: MatSnackBar,
    public dialog: MatDialog

  ) {
    
  }

  ngOnInit() {
    this.nrc = { 'ALL': 0, 'PCIN': 0, 'PCINFO': 0, 'PCCA': 0, 'OFICIO': 0, 'DEC': 0, 'OTHERS': 0 };
    this.fillTray();
    this.filterMails(this.currentTab);
    this.route.queryParams.subscribe(qp => {
      this.page = qp.hasOwnProperty('page') ? qp.page : 1;
    });
  }

  tabChanged(evt) {
    this.filterMails(this.tabs.filter((tab, idx) => {
      if (idx === evt.index) { return tab.notReaded; }
    })[0].notReaded);
  }

  searchMails(e) {
    e = e.toLowerCase();
    const filter = this.mails.filter((mail) => {
      if (
        mail.subject.replace(/á|é|í|ó|ú/gi, '').toLowerCase().includes(e)
        || mail.firstname.toLowerCase().includes(e)
        || mail.lastname.toLowerCase().includes(e)
        || mail.correlativo.toLowerCase().includes(e)
        || mail.date.includes(e)
      ) {
        return mail;
      }
    });
    this.cpm = filter;
  }

  archive() {
    if (this.selected < 1) { return; }

    if (this.type !== 'archivadas') {
      this.mail.archive(this.selected, 1).subscribe(res => {
        this.snack.dismiss();
        this.snack.open('Archivado', 'ok', {
          duration: 1500
        });
      }, err => {
        this.snack.open('Error. No se ha podido archivar.', 'ok');
      });
    } else {
      this.mail.archive(this.selected, 0).subscribe(res => {
        this.snack.dismiss();
        this.fillTray();
        this.snack.open('Desarchivado', 'ok', {
          duration: 1500
        });
      }, err => {
        this.snack.open('Error. No se ha podido desarchivar.', 'ok');
      });
    }
  }

  pagEvt(evt) {
    this.page = evt;
    this.router.navigate(['bandeja/', this.type], {
      queryParams: {
        page: evt
      }
    });
  }

  filterMails(doctype = 'ALL') {
    if (doctype === 'ALL') { return this.cpm = this.mails; }
    if (doctype === 'OTHERS') {
      return this.cpm = this.mails.filter((mail) => {
        const dn = mail.docname;
        if (dn === 'RES' || dn === 'ERR' || dn === 'DES' || dn === 'PCEG') { return mail; }
      });
    }
    const filter = this.mails.filter((mail) => {
      if (mail.docname === doctype) {
        return mail;
      }
    });
    this.cpm = filter;
  }

  showOptions(e, mail, item) {
    document.getElementById(item).classList.toggle('selected');
    if (e.checked) {
      this.selected.push(mail.id);
    } else {
      const el = this.selected.indexOf(mail.id);
      this.selected.splice(el, 1)[0];
    }
    this.isSelected = (this.selected.length > 0);
  }

  openMail(mail) {
    this.nr = this.nr - 1;
    this.mail.notReadedMails({ tray: this.type, nry: this.nr });
    localStorage.setItem('trayCan', JSON.stringify(this.trayCan));
    const task = (this.type === 'asignadas') ? { queryParams: { 'task': mail.task } } : {};
    this.router.navigate(['leer', mail.id], task);
  }

  count() {
    this.nr = 0;
    this.nrc = { 'ALL': 0, 'PCIN': 0, 'PCINFO': 0, 'PCCA': 0, 'OFICIO': 0, 'DEC': 0, 'OTHERS': 0 };
    this.mails.map((mail) => {
      if (mail.readed === 0) {
        this.nrc['ALL']++;
        this.nrc.hasOwnProperty(mail.docname) ? this.nrc[mail.docname]++ : this.nrc['OTHERS']++;
      }
    });
    this.setTabs();
  }

  fillTray() {
    this.mails = [];
    this.sub = this.route.params.subscribe(params => {
      this.type = params['type'] === 'redactadas' || params['type'] === 'rechazadas' ||
        params['type'] === 'diferidas' || params['type'] === 'aprobadas' || params['type'] === 'recibidas' ||
        params['type'] === 'asignadas' || params['type'] === 'archivadas' || params['type'] === 'enviadas' ||
        params['type'] === 'borradores' || params['type'] === 'por-aprobar' ? params['type'] : 'recibidas';
      this.trayCan = this.configureTray(this.type);
      this.loading = true;
      this.page = (params['page'] - 1) || 0;
      this.isSelected = false;
      this.selected = [];
      this.mail.getTray(this.type)
        .subscribe(data => {
          this.mails = data;
          this.cpm = this.mails;
          this.count();
          this.mail.notReadedMails({ tray: this.type, nry: this.nr });
          this.loading = false;
          this.snack.dismiss();
        }, error => {
          this.snack.open('No se ha podido recibir las correspondencias, revise su conexión a internet.', 'ok');
          this.loading = false;
        });

    });

  }

  private configureTray(type) {
    const cfg: trayConfig = {
      Archive: true, Aprove: false, Assign: false, Resend: false,
      NotReadedMark: false, Respond: false, Out: false, Revoke: false, Edit: false
    };
    switch (type) {
      case 'recibidas':
        cfg.NotReadedMark = true; cfg.Aprove = false; cfg.Assign = true; cfg.Resend = true;
        break;
      case 'enviadas':
        cfg.Resend = true;
        break;
      case 'aprobadas':
        cfg.Assign = true; cfg.Resend = true;
        break;
      case 'por-aprobar':
        cfg.Archive = false;
        cfg.Assign = true; cfg.Aprove = true; cfg.NotReadedMark = true;
        break;
      case 'archivadas':
        break;
      case 'asignadas':
        cfg.Archive = false;
        cfg.Respond = true;
        break;
      case 'redactadas':
        cfg.Archive = false;
        cfg.Out = true;
        cfg.Revoke = true;
        cfg.Edit = true;
        break;
      case 'rechazadas':
        break;
      default:
        cfg.Aprove = true; cfg.Assign = true; cfg.Resend = true;
        break;
    }
    return cfg;

  }

  mailInfo(xid:string|number) {
    console.log(xid);
    this.dialog.open(MailInfoComponent, {
      data: xid,
      height: '400px'
    });
  }

  setTabs() {
    this.tabs = [
      { title: 'Todos', notReaded: 'ALL' },
      { title: 'Ingresos', notReaded: 'PCIN' },
      { title: 'PC Informativos', notReaded: 'PCINFO' },
      { title: 'Creditos adicionales', notReaded: 'PCCA' },
      { title: 'Oficios', notReaded: 'OFICIO' },
      { title: 'Decretos', notReaded: 'DEC' },
      { title: 'Otros', notReaded: 'OTHERS' }
    ];
  }
}

export interface trayConfig {
  Archive: boolean;
  Aprove: boolean;
  Assign: boolean;
  Resend: boolean;
  Respond: boolean;
  Revoke: boolean;
  Edit: boolean;
  NotReadedMark: boolean;
  Out: boolean;
}
