export class Utils {
	public static months = [
		'Enero',
		'Febrero',
		'Marzo',
		'Abril',
		'Mayo',
		'Junio',
		'Julio',
		'Agosto',
		'Septiembre',
		'Octubre',
		'Noviembre',
		'Diciembre',
	];

	public static days = [
		'Uno',
		'Dos',
		'Tres',
		'Cuatro',
		'Cinco',
		'Seis',
		'Siete',
		'Ocho',
		'Nueve',
		'Diez',
		'Once',
		'Doce',
		'Trece',
		'Catorce',
		'Quince',
		'Dieciseis',
		'Diecisiete',
		'Dieciocho',
		'Diecinueve',
		'Veinte',
		'Veintiuno',
		'Veintidos',
		'Veintitres',
		'Veinticuatro',
		'Veinticinco',
		'Veintiseis',
		'Veintisiete',
		'Veintiocho',
		'Veintinueve',
		'Treinta',
		'Treintaiuno'
	];

}