import { Component } from '@angular/core';
import { MailService } from '../../services/mail/mail.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material';
import { SendToComponent, OPERATION_TYPE } from '../write/send-to/send-to.component';
import { SwalComponent } from '@toverux/ngx-sweetalert2';
import { MatSnackBar } from '@angular/material';
import { NgxQRCodeComponent } from 'ngx-qrcode2';
import { trayConfig } from '../home/home/home.component';

@Component({
    selector: 'app-read',
    templateUrl: './read.component.html',
    styleUrls: ['./read.component.scss']
})
export class ReadComponent {

    public mail;
    public attachments = [];
    public loading = true;
    public differDate: any;
    public task: string;
    public assignation: any;
    private userCan: trayConfig;
    public toastClosed;
    public user;

    constructor(
        public route: ActivatedRoute,
        public dialog: MatDialog,
        public snack: MatSnackBar,
        public router: Router,
        private mailService: MailService
    ) {
        this.userCan = JSON.parse(localStorage.getItem('trayCan'));
        this.user = JSON.parse(localStorage.getItem('user'));
        this.getMail();
        this.route.queryParams.subscribe(res => {
            this.task = res.task || null;
            if (this.task) { this.showTaskSwal(); }
        });

    }

    showTaskSwal() {
        this.toastClosed = false;
        new SwalComponent({
            title: '<h5 style="font-family:Spinnaker;">Asignación.</h5>',
            type: 'info',
            toast: true,
            html: '<p style="font-family:arial;">' + this.task + '</p>',
            position: 'bottom-right'
        }).show().then(() => { this.toastClosed = true; });
    }

    gen(mail) {
        const qr = new NgxQRCodeComponent(null);
        qr.value = `http://api-siscor.guarico.gob.ve/qr/${mail.id}`;
        qr.elementType = 'url';
        qr.errorCorrectionLevel = 'L';
        return qr;
    }

    assignDialog() {
        const assignDialog = this.dialog.open(SendToComponent, {
            data: {
                maxReceivers: 1,
                data: this.mail.id,
                operation: OPERATION_TYPE.ASSIGN
            },
            width: '800px'
        });
        assignDialog.afterClosed().subscribe(res => {
            if (res) {
                new SwalComponent({ html: '<p style="font-family:Spinnaker arial;">Asignado</p>',
                 timer: 2000, position: 'bottom-right' }).show();
            }
        }, error => {
            console.log(error);
        });
    }

    resend() {
        const resendDialog = this.dialog.open(SendToComponent, {
            data: {
                maxReceivers: -1,
                mail: this.mail.id,
                operation: OPERATION_TYPE.RESEND
            },
            width: '800px'
        });
        resendDialog.afterClosed().subscribe(res => {
            if (res) {
                new SwalComponent({ html: '<p style="font-family:Spinnaker arial;">Reenviado</p>',
                 toast: true, timer: 2000, position: 'bottom-right' }).show();
            }
        }, error => {
            console.log(error);
        });
    }

    revoke() {
        new SwalComponent({
            type: 'question',
            title: '<h2 style="font-family:Spinnaker">Confirmar</h2>',
            showCancelButton: true,
            cancelButtonText: 'NO',
            confirmButtonText: 'SI'
        })
            .show().then(result => {
                if (result.dismiss) { return; }
                this.mailService.revoke(this.mail.id)
                    .subscribe(res => {
                        new SwalComponent({ type: 'info', toast: true, position: 'bottom-right',
                         html: '<p style="font-family:arial;">Eliminado</p>' }).show();
                        this.router.navigate(['/bandeja/redactadas']);
                    }, error => {
                        console.log(error);
                    });
            }).catch(error => {
                console.log(error);
            });
    }

    edit() {
        localStorage.setItem('currentEditingDoc', JSON.stringify(this.mail));
        this.router.navigate(['/editar']);
    }

    respondAssigned() {
        new SwalComponent({
            title: '<h5 style="font-family:Spinnaker">Responder asignación.</h5>',
            input: 'textarea',
            inputPlaceholder: 'Escriba aqui...',
            showCancelButton: true
        }).show()
            .then(result => {
                if (result.dismiss) { return; }
                this.mailService.respondAssign({
                    id: this.mail.id,
                    response: result.value
                }).subscribe(res => {
                    new SwalComponent({ html: '<p style="font-family:Spinnaker arial;">Listo</p>',
                     timer: 2000, position: 'bottom-right' }).show();
                    this.router.navigate(['/bandeja/asignadas']);
                }, error => {
                    console.log(error);
                });
            }).catch(err => {
                console.log(err);
            });
    }

    generatePDF() {
        this.mailService.makePdf(this.mail)
            .subscribe((res: any) => {
                const _blob = new Blob([res], { type: 'application/pdf' });
                console.log(_blob);
                const url = window.URL.createObjectURL(_blob);
                const a = document.createElement('a');
                document.body.appendChild(a);
                a.setAttribute('style', 'display: none');
                a.href = url;
                a.download = this.mail.correlativo + '.pdf';
                a.click();
                window.URL.revokeObjectURL(url);
            }, error => {
                console.log('Fucking Error');
            });
    }

    aprobar() {
        new SwalComponent({
            title: '<h2 style="font-family:Spinnaker">Aprobar</h2>',
            input: 'textarea',
            inputPlaceholder: 'Comentarios. (opcional)',
            showCancelButton: true
        }).show().then(result => {
            if (result.dismiss) { return; }
            this.loading = true;
            this.mailService.aprobar({
                mail: this.mail.id,
                data: this.addComments(result.value, true)
            }).subscribe(res => {
                const swal = new SwalComponent({
                    type: 'success',
                    text: 'Aprobado',
                    timer: 1500
                });
                this.mail.aprobado = 1;
                this.router.navigate(['/bandeja/por-aprobar']);
                swal.show();
                this.loading = false;
            }, error => {
                new SwalComponent({ text: 'No se ha podido aprobar' }).show();
            });
        }).catch(err => {
            console.log(err);
        });

    }

    mailOut() {

        this.mail.content

        this.mailService.mailOut(this.mail.id).subscribe((res: { success: boolean }) => {
            if (res.success) {
                new SwalComponent({ type: 'success', toast: true, html: '<p style="font-family:arial">Aprobado</p>' })
                    .show().then(() => {
                        // this.route.navigate(['bandejas/enviadas']);
                    });
            }
        }, error => {
            console.log(error);
        });
    }

    reject() {
        new SwalComponent({
            title: '<h2 style="font-family:Spinnaker">Rechazar</h2>',
            input: 'textarea',
            inputPlaceholder: 'Razon por la cual rechaza. (opcional)',
            showCancelButton: true
        }).show().then(result => {
            if (result.dismiss) { return; }
            this.mailService.reject({
                id: this.mail.id,
                data: this.addComments(result.value, false)
            }).subscribe(res => {
                console.log(res);
                this.router.navigate(['/bandeja/por-aprobar']);
            }, error => {
                console.log(error);
            });
        }).catch(error => {
            console.log(error);
        });

    }

    editorReady(e) {
        this.loading = false;
    }

    download(ad) {
        this.mailService.download(ad.path).subscribe((res: ArrayBuffer) => {
            const _blob = new Blob([res], { type: 'application/octet-stream' });
            const url = window.URL.createObjectURL(_blob);
            const a = document.createElement('a');
            document.body.appendChild(a);
            a.setAttribute('style', 'display: none');
            a.href = url;
            a.download = ad.human_name;
            a.click();
            window.URL.revokeObjectURL(url);
        }, error => {
            console.log(error);
        });
    }

    differDateChange(ev) {
        const differDate = ev.value.toLocaleDateString('es-VE');
        new SwalComponent({
            type: 'question',
            title: '<h2 style="font-family:Spinnaker">Confirmar</h2>',
            showCancelButton: true,
            cancelButtonText: 'NO',
            confirmButtonText: 'SI',
            html: '<p style="font-family:Spinnaker">¿Diferir hasta la fecha <strong style="color:red;">' + differDate + '</strong>?</p>'
        })
            .show().then((result) => {
                if (result.dismiss) { return; }
                this.mailService.diff({ id: this.mail.id, differ: ev.value })
                    .subscribe(res => {
                        console.log(res);
                    }, error => {
                        console.log(error);
                    });
            }).catch(error => {
                console.log(error);
            });
    }

    addComments(comments: any, aproved: boolean) {
        const des = aproved ? 'APROBADO' : 'RECHAZADO';
        const sign = localStorage.getItem('sign');
        const seal = localStorage.getItem('seal');

        const swidth = this.mail.mail_type == 6 || this.mail.mail_type == 7 ? '180px' : '350px';
        const sealwidth = this.mail.mail_type == 6 || this.mail.mail_type == 7 ? '100px' : '150px';        

        if(this.mail.mail_type == 6 && this.user.organism.id == 12 && this.user.isAprobador == 1) {
            this.mail.data = this.mail.data.replace('<!--sign-->', `<img width='${swidth}'; src=${sign}></img>`);
            this.mail.data = this.mail.data.replace('<!--seal-->', `<img width='${sealwidth}'; src=${seal}></img>`);
        }

        this.mail.data = this.mail.data.replace('<!--g-sign-->', '<img width="'+swidth+'"; src=' + sign + '></img>');
        this.mail.data = this.mail.data.replace('<!--g-seal-->', '<img width="'+sealwidth+'"; src=' + seal + '></img>');
        this.mail.data = this.mail.data.replace('<!--decision-->', des);
        this.mail.data = this.mail.data.replace('<!--comments-->', comments);
        return this.mail.data;
    }

    getMail() {

        this.route.params.subscribe(params => {
            const ref = params['mail'];
            this.mailService.getMail(ref)
                .subscribe(data => {
                    if (data['mail'].correlativo) {
                        data['mail'].data = data['mail'].data.replace('<!--correlativo-->', data['mail'].correlativo);
                        const date = new Date(data['mail'].created_at);
                        data['mail'].data = data['mail'].data.replace('<!--date-->', date.toLocaleString());
                        data['mail'].data = this.generateLinks(data['mail'].data);
                        this.gen(data['mail']).toDataURL().then((qr) => {
                            data['mail'].data = data['mail'].data.replace('<!--qrcode-->', '<img width="150" src="' + qr + '"></img>');
                        }).catch(error => {
                            console.log(error);
                        });
                    }
                    this.assignation = data['assigned'][0];
                    this.mail = data['mail'];
                    this.attachments = this.mail.attached;
                }, error => {
                    this.snack.open('¡Error! revise su conexión a internet e intente de nuevo.', 'ok');
                });
        });
    }

    generateLinks(content: string) {
        let correlative = null;
        try {
            correlative = content.match(/##(.*?)##/).pop();
        } catch (e) {
            return content;
        }
        localStorage.setItem('currentReference', correlative);
        const result = content.replace(/##(.*?)##/g,
         `<a href="${'http://correspondencia.guarico.gob.ve/leer/ref'}" target="_blank">${correlative}</a>`);
        return result;
    }

}
