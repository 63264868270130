import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, FormControlName } from '@angular/forms';
import { AuthServiceService } from '../../../services/auth/auth-service.service';
import { SwalComponent } from '@toverux/ngx-sweetalert2';

@Component({
  selector: 'app-create-user',
  templateUrl: './create-user.component.html',
  styleUrls: ['./create-user.component.scss']
})
export class CreateUserComponent implements OnInit {

  personFormGroup 		 : FormGroup;
  userFormGroup 			 : FormGroup;
  institutionFormGroup : FormGroup;
  organisms : any;
  charges   : any;
  permissions : any;

  constructor(
  	public _formBuilder : FormBuilder,
    private authService : AuthServiceService
  	) {}

  ngOnInit() {

  	this.personFormGroup = this._formBuilder.group({
  		firstname : new FormControl('', [Validators.required, Validators.maxLength(30), Validators.minLength(3)]),
  		lastname  : new FormControl('', [Validators.required, Validators.maxLength(30), Validators.minLength(3)]),
  		dni				: new FormControl('', [Validators.required, Validators.maxLength(8), Validators.minLength(7)]),
  		initials  : new FormControl('', [Validators.required, Validators.maxLength(4), Validators.minLength(2)])
  	});

  	this.userFormGroup = this._formBuilder.group({
  		username   : new FormControl('', [Validators.required, Validators.maxLength(15), Validators.minLength(5)]),
  		email      : new FormControl('', [Validators.required]),
  		password	 : new FormControl('', [Validators.required, Validators.minLength(4)]),
  		c_password : new FormControl('', [Validators.required, Validators.minLength(4)]),
      organism   : new FormControl('', [Validators.required]),
      charge     : new FormControl('', [Validators.required]),
      charge_h   : new FormControl('', [Validators.required]),
      permission : new FormControl('', [Validators.required]),
      usertype   : new FormControl('', [Validators.required])
  	});

    this.getOrganism();
    this.getCharges();
    this.getPermissions();  
  }

  autoFill(event):void{
  	let fn = this.personFormGroup.value.firstname || '';
  	let ln = this.personFormGroup.value.lastname  || '';
  	
  	let _fn = fn[0] || '';
  	let _ln = ln[0] || '';

  	this.personFormGroup.get('initials').setValue((_fn+_ln).toUpperCase());
  	this.userFormGroup.get('username').setValue((_fn[0]+ln).toLowerCase());
  	this.userFormGroup.get('password').setValue((_fn[0]+ln).toLowerCase());
  	this.userFormGroup.get('c_password').setValue((_fn[0]+ln).toLowerCase());

  }


  getChargeVal(evt){
    console.log(this.userFormGroup.value);
  }

  createUser(){

    let redactor = false;
    let aprobador = false;

    if(this.userFormGroup.get('usertype').value == 1){
      aprobador = true;
    }else if(this.userFormGroup.get('usertype').value == 2){
      redactor = true;
    }

    let user = {
      "username"   : this.userFormGroup.get('username').value,
      "email"      : this.userFormGroup.get('email').value,
      "password"   : this.userFormGroup.get('password').value,
      "c_password" : this.userFormGroup.get('c_password').value,
      "organism_id": this.userFormGroup.get('organism').value,
      "charge_id"  : this.userFormGroup.get('charge').value,
      "charge_h"   : this.userFormGroup.get('charge_h').value,
      "firstname"  : this.personFormGroup.get('firstname').value,
      "lastname"   : this.personFormGroup.get('lastname').value,
      "dni"        : this.personFormGroup.get('dni').value,
      "role_id"    : 2,
      "name_initials"   : this.personFormGroup.get('initials').value,
      "permission_id" : this.userFormGroup.get('permission').value,
      "redactor" : redactor,
      "aprobador" : aprobador
    };

    this.authService.createUser(user)
    .subscribe(res => {
      let s = new SwalComponent({
        type: 'success',
        title: 'Usuario agregado',
        toast : true,
        showConfirmButton: false,
        timer: 3000        
      });
      s.show();
      this.personFormGroup.reset();
      this.userFormGroup.reset();
    }, error => {
      let s = new SwalComponent({
        position: 'bottom-right',
        type: 'error',
        title: 'No se ha podido crear el usuario',
        text: `El usuario ${this.userFormGroup.get('username').value} ya ha sido registrado`,
        showConfirmButton: false,
        timer: 3000        
      });
      s.show();
    });
  }

  getCharges(){
    
    this.authService.getCharges().subscribe(res => {
      this.charges = res;
    }, error => {
      console.log(error);
    });
  }

  getPermissions(){
    this.authService.getPermissions().subscribe(res => {
      console.log(res);
      this.permissions = res;
    }, error => {
      console.log(error);
    });
  }

  getOrganism(){
    this.authService.getOrganisms().subscribe(res => {
      this.organisms = res;
    }, error => {
      console.log(error);
    });
  }

}
