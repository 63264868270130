import { Component, OnInit } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material';
import { MailService } from '../../../services/mail/mail.service';

@Component({
  selector: 'app-format-sheet',
  templateUrl: './format-sheet.component.html',
  styleUrls: ['./format-sheet.component.scss']
})

export class FormatSheetComponent implements OnInit {

	public formats = [];

  constructor(private bottomSheetRef : MatBottomSheetRef<FormatSheetComponent>, private ms : MailService) { 
    this.formats = JSON.parse(localStorage.getItem('permissions') || '{formats: []}').formats;
   }

  ngOnInit() {
  }

  setFormat(code){
    if(code == 7 || code == 6 || code == 11){
      if(!localStorage.getItem("sg")){
        this.ms.sgCreds().subscribe((res : {sg : string}) => {
          localStorage.setItem("sg", res.sg);
          this.bottomSheetRef.dismiss(code);
        }, error => {
          console.log(error);
        });
      }else{
        this.bottomSheetRef.dismiss(code);  
      }

    if(!localStorage.getItem("sgs")){
        this.ms.sgSeal().subscribe((res : {sg : string}) => {
          localStorage.setItem("sgs", res.sg);
          this.bottomSheetRef.dismiss(code);
        }, error => {
          console.log(error);
        });
      }else{
        this.bottomSheetRef.dismiss(code);  
      }

  }else{
      this.bottomSheetRef.dismiss(code);
  }

  }
}