import { Component, OnInit } from '@angular/core';
import { AbstractDocument } from '../abstract-doc';

@Component({
  selector: 'app-oficio',
  templateUrl: './oficio.component.html',
  styleUrls: ['./oficio.component.scss']
})
export class OficioComponent extends AbstractDocument implements OnInit {

  constructor() {
    super();
  }

  fillOficio(user?) {
    if (user == undefined) user = this.user;
    this.code = 1;
    return this.content = `
    <!DOCTYPE html>
    <html>
      <head>
      </head>
        <body>
          <table style="border-collapse: collapse; width: 100%;" border="0" class="mceNonEditable">
          <tbody>
          <tr>
          <td style="width: 20%;"><img style="float: left;" src="${this.logo}" /></td>
          <td style="width: 60%; text-align: center; font-family:arial; font-size:13px; font-weight:bold;">${this.mem}</td>
          <td style="width: 20%;"><img style="float: right;" src="${this.escudo}" /></td>
          </tr>
          </tbody>
          </table>
        <div>
          <table style="border-collapse: collapse; width: 100%;" border="0">
          <tbody>
          <tr>
          <td style="width: 50%; font-family: arial;">
          <p style="font-size:12px;" class="mceNonEditable"><strong><!--correlativo--></strong></p>
          </td>
          <td style="width: 50%; font-family: arial;">
          <p style="font-size:12px; text-align:right;"><strong>San Juan de los Morros,</strong></p>
          <p style="font-size:12px; text-align:right;"><strong><!--date--></strong></p>
          </td>
          </tr>
          </tbody>
          </table>
        </div>  
        <div>
          <p id="focus" style="font-family:arial;">CUERPO DEL OFICIO.</p>
        </div>  
        <footer>
          <table style="border-collapse: collapse; width: 100%; border="0" >
          <tbody>
          <tr>
          <td style="width: 33.3%; font-family: arial;">
            <!--seal-->
          </td>
          <td style="width: 33.3%; font-family: arial; text-align:center;" class="mceNonEditable">
            <!--sign-->
            <!--gaceta-->
          </td>
          <td align="right" style="width: 33.3%; font-family: arial;" class="mceNonEditable">
            <!--qrcode-->
          </td>
          </tr>
          </tbody>
          </table>
        </footer>
      </body>
    </html>
    `;

  }

  fillReporteInfo(user?) {
    if (user == undefined) user = this.user;
    this.code = 10;
    return this.content = `
    <!DOCTYPE html>
    <html>
      <head>
      </head>
        <body>
          <table style="border-collapse: collapse; width: 100%;" border="0" class="mceNonEditable">
          <tbody>
          <tr>
          <td style="width: 20%;"><img style="float: left;" src="${this.dgi_shield}" /></td>
          <td style="width: 60%; text-align: center; font-family:arial; font-size:13px; font-weight:bold;">${this.mem}</td>
          <td style="width: 20%;"><img style="float: right;" src="${this.escudo}" /></td>
          </tr>
          </tbody>
          </table>
        <div>
          <table style="border-collapse: collapse; width: 100%;" border="0">
          <tbody>
          <tr>
          <td style="width: 50%; font-family: arial;">
          <p style="font-size:12px;" class="mceNonEditable"><strong><!--correlativo--></strong></p>
          </td>
          <td style="width: 50%; font-family: arial;">
          <p style="font-size:12px; text-align:right;"><strong>San Juan de los Morros,</strong></p>
          <p style="font-size:12px; text-align:right;"><strong><!--date--></strong></p>
          </td>
          </tr>
          </tbody>
          </table>
        </div>
        <p style="text-align: center;"><strong>REPORTE DE SERVICIO</strong></p>
        <p style="text-align: center;">&nbsp;</p>
        <table style="border-collapse: collapse; width: 100%;" border="1">
        <tbody>
        <tr>
        <td style="width: 50%; text-align: center;">
        <p>SECRETAR&Iacute;A/ENTE/INSTITUCI&Oacute;N</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        </td>
        <td style="width: 50%; text-align: center;">
        <p>DIRECCI&Oacute;N DE INFORM&Aacute;TICA</p>
        <p>FUNCIONARIOS:</p>
        <p style="text-align: left;">NOMBRES:</p>
        <p><span style="text-decoration: underline;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span></p>
        <p><span style="text-decoration: underline;">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span></p>
        </td>
        </tr>
        </tbody>
        </table>
        <p>&nbsp;</p>
        <p style="text-align: center;"><strong>TIPO DE SERVICIO</strong></p>
        <p style="text-align: left;">&nbsp;</p>
        <table style="border-collapse: collapse; width: 100%;" border="1">
        <tbody>
        <tr>
        <td style="width: 33.3333%; visibility: hidden;">&nbsp;</td>
        <td style="width: 33.3333%; text-align: center;">DETALLES</td>
        <td style="width: 33.3333%; text-align: center;">OBSERVACIONES</td>
        </tr>
        <tr>
        <td style="width: 33.3333%;">HARDWARE</td>
        <td style="width: 33.3333%;">&nbsp;</td>
        <td style="width: 33.3333%;">&nbsp;</td>
        </tr>
        <tr>
        <td style="width: 33.3333%;">SOFTWARE</td>
        <td style="width: 33.3333%;">&nbsp;</td>
        <td style="width: 33.3333%;">&nbsp;</td>
        </tr>
        <tr>
        <td style="width: 33.3333%;">REDES</td>
        <td style="width: 33.3333%;">&nbsp;</td>
        <td style="width: 33.3333%;">&nbsp;</td>
        </tr>
        <tr>
        <td style="width: 33.3333%;">SISAP</td>
        <td style="width: 33.3333%;">&nbsp;</td>
        <td style="width: 33.3333%;">&nbsp;</td>
        </tr>
        <tr>
        <td style="width: 33.3333%;">CORRESPONDENCIA</td>
        <td style="width: 33.3333%;">&nbsp;</td>
        <td style="width: 33.3333%;">&nbsp;</td>
        </tr>
        <tr>
        <td style="width: 33.3333%;">OTROS</td>
        <td style="width: 33.3333%;">&nbsp;</td>
        <td style="width: 33.3333%;">&nbsp;</td>
        </tr>
        </tbody>
        </table>
        <p>&nbsp;</p>
        <p>CANTIDAD DE EQUIPOS EXAMINADOS:&nbsp;</p>
        <p>&nbsp;</p>
        </div>
        <div>
          <table style="border-collapse: collapse; width: 100%; border="0" >
          <tbody>
          <tr>
          <td style="width: 33.3%; font-family: arial;">
            <!--seal-->
          </td>
          <td style="width: 33.3%; font-family: arial; text-align:center;" class="mceNonEditable">
            <!--sign-->
            <!--gaceta-->
          </td>
          <td align="right" style="width: 33.3%; font-family: arial;" class="mceNonEditable">
            <!--qrcode-->
          </td>
          </tr>
          </tbody>
          </table>
        </div>
      </body>
    </html>
    `;
  }

  fillPresupuestaria(user?) {
    if (user == undefined) user = this.user;
    this.code = 12;
    let fecha = new Date();
    return this.content = `
    <!DOCTYPE html>
    <html>
      <head>
      </head>
        <body>
        <table class="MsoNormalTable" style="width: 100%; border-collapse: collapse; border: none; mso-border-alt: solid windowtext .5pt; mso-padding-alt: 0cm 3.5pt 0cm 3.5pt; mso-border-insideh: .5pt solid windowtext; mso-border-insidev: .5pt solid windowtext;" border="1" width="100%" cellspacing="0" cellpadding="0">
        <tbody>
        <tr style="mso-yfti-irow: 0; mso-yfti-firstrow: yes; height: 25.95pt;">
        <td style="width: 20%; border: solid windowtext 1.0pt; border-right: none; mso-border-alt: solid windowtext .5pt; padding: 0cm 3.5pt 0cm 3.5pt; height: 25.95pt;" rowspan="2" valign="top">
        <img style="float: left;" src="${this.escudo}"</td>
        <td style="width: 229.6pt; border: solid windowtext 1.0pt; border-left: none; mso-border-left-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt; padding: 0cm 3.5pt 0cm 3.5pt; height: 25.95pt;" rowspan="2" valign="top" width="306">
        <p class="MsoNormal" style="margin-bottom: .0001pt; text-align: center; line-height: normal;" align="center"><span style="font-size: 10pt;"><strong><span style="font-family: 'Arial','sans-serif';">Rep&uacute;blica Bolivariana de Venezuela</span></strong></span></p>
        <p class="MsoNormal" style="text-align: center; line-height: normal;" align="center"><span style="font-size: 10pt;"><strong><span style="font-family: 'Arial','sans-serif';">Estado Bolivariano de Gu&aacute;rico</span></strong></span></p>
        <p class="MsoNormal" style="text-align: center; line-height: normal;" align="center">&nbsp;</p>
        <p class="MsoNormal" style="text-align: center; line-height: normal;" align="center"><span style="font-size: 10pt;"><strong><span style="font-family: 'Arial','sans-serif';">SECRETAR&Iacute;A DE PLANIFICACI&Oacute;N Y PRESUPUESTO</span></strong></span></p>
        <td style="width: 153.15pt; border: solid windowtext 1.0pt; border-left: none; mso-border-left-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt; padding: 0cm 3.5pt 0cm 3.5pt; height: 25.95pt;" valign="top" width="204">
        <p class="MsoNormal" style="line-height: normal; margin: 6.0pt 0cm .0001pt 0cm;"><span style="font-family: arial, helvetica, sans-serif; font-size: 10pt;">N&ordm; <!--correlativo--></span></p>
        </td>
        </tr>
        <tr style="mso-yfti-irow: 1; mso-yfti-lastrow: yes; height: 26.8pt;">
        <td style="width: 153.15pt; border-top: none; border-left: none; border-bottom: solid windowtext 1.0pt; border-right: solid windowtext 1.0pt; mso-border-top-alt: solid windowtext .5pt; mso-border-left-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt; padding: 0cm 3.5pt 0cm 3.5pt; height: 26.8pt;" valign="top" width="204">
        <p class="MsoNormal" style="line-height: normal; margin: 6.0pt 0cm .0001pt 0cm;"><span style="font-family: arial, helvetica, sans-serif; font-size: 10pt;">FECHA <!--date--></span></p>
        </td>
        </tr>
        </tbody>
        </table>
        <p class="MsoNormal" style="text-align: center;" align="center">&nbsp;</p>
        <p class="MsoNormal" style="text-align: center;" align="center"><strong><u><span style="font-family: 'Arial','sans-serif';">CERTIFICACI&Oacute;N DE DISPONIBILIDAD PRESUPUESTARIA</span></u></strong></p>
        <p style="text-align: center;">&nbsp;</p>
        <p class="MsoNormal" style="text-align: justify;">&nbsp;</p>
        <p class="MsoNormal" style="text-align: justify;"><span style="font-family: 'Arial','sans-serif';">Por medio de la presente, se procede a la verificaci&oacute;n y an&aacute;lisis de los movimientos reflejados en la Ejecuci&oacute;n Presupuestaria correspondientes al ejercicio econ&oacute;mico financiero ${fecha.getFullYear()}, que inciden con cada una de las partidas objeto de la solicitud elevada por ante esta Secretar&iacute;a de acuerdo a la informaci&oacute;n suministrada y que se detalla de la manera siguiente:</span></p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <table class="MsoNormalTable" style="border-collapse: collapse; mso-table-layout-alt: fixed; border: none; mso-border-alt: solid windowtext .5pt; mso-padding-alt: 0cm 3.5pt 0cm 3.5pt; mso-border-insideh: .5pt solid windowtext; mso-border-insidev: .5pt solid windowtext; width: 100%;" border="1" width="100%" cellspacing="0" cellpadding="0">
        <tbody>
        <tr style="mso-yfti-irow: 0; mso-yfti-firstrow: yes; height: 20.55pt;">
        <td style="width: 70.95pt; border: solid windowtext 1.0pt; mso-border-alt: solid windowtext .5pt; padding: 0cm 3.5pt 0cm 3.5pt; height: 20.55pt;" valign="top" width="95">
        <p class="MsoNormal" style="text-align: center; line-height: normal; margin: 6.0pt 0cm .0001pt 0cm;" align="center"><span style="font-family: arial, helvetica, sans-serif; font-size: 10pt;"><strong>Dependencia solicitante</strong></span></p>
        </td>
        <td style="width: 3.0cm; border: solid windowtext 1.0pt; border-left: none; mso-border-left-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt; padding: 0cm 3.5pt 0cm 3.5pt; height: 20.55pt;" valign="top" width="113">
        <p class="MsoNormal" style="text-align: center; line-height: normal; margin: 6.0pt 0cm .0001pt 0cm;" align="center"><span style="font-family: arial, helvetica, sans-serif; font-size: 10pt;">&nbsp;</span></p>
        </td>
        <td style="width: 49.6pt; border: solid windowtext 1.0pt; border-left: none; mso-border-left-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt; padding: 0cm 3.5pt 0cm 3.5pt; height: 20.55pt;" colspan="2" valign="top" width="66">
        <p class="MsoNormal" style="text-align: center; line-height: normal; margin: 6.0pt 0cm .0001pt 0cm;" align="center"><span style="font-family: arial, helvetica, sans-serif; font-size: 10pt;"><strong>N&ordm; Oficio</strong></span></p>
        </td>
        <td style="width: 92.15pt; border: solid windowtext 1.0pt; border-left: none; mso-border-left-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt; padding: 0cm 3.5pt 0cm 3.5pt; height: 20.55pt;" colspan="3" valign="top" width="123">
        <p class="MsoNormal" style="text-align: center; line-height: normal; margin: 6.0pt 0cm .0001pt 0cm;" align="center"><span style="font-family: arial, helvetica, sans-serif; font-size: 10pt;"><!--correlativo--></span></p>
        </td>
        <td style="width: 35.4pt; border: solid windowtext 1.0pt; border-left: none; mso-border-left-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt; padding: 0cm 3.5pt 0cm 3.5pt; height: 20.55pt;" colspan="2" valign="top" width="47">
        <p class="MsoNormal" style="text-align: center; line-height: normal; margin: 6.0pt 0cm .0001pt 0cm;" align="center"><span style="font-family: arial, helvetica, sans-serif; font-size: 10pt;"><strong>Fecha</strong></span></p>
        </td>
        <td style="width: 63.8pt; border: solid windowtext 1.0pt; border-left: none; mso-border-left-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt; padding: 0cm 3.5pt 0cm 3.5pt; height: 20.55pt;" valign="top" width="85">
        <p class="MsoNormal" style="text-align: center; line-height: normal; margin: 6.0pt 0cm .0001pt 0cm;" align="center"><span style="font-family: arial, helvetica, sans-serif; font-size: 10pt;"><!--date--></span></p>
        </td>
        <td style="width: 70.9pt; border: solid windowtext 1.0pt; border-left: none; mso-border-left-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt; padding: 0cm 3.5pt 0cm 3.5pt; height: 20.55pt;" valign="top" width="95">
        <p class="MsoNormal" style="line-height: normal; margin: 6.0pt 0cm .0001pt 0cm;"><span style="font-family: arial, helvetica, sans-serif; font-size: 10pt;"><strong>Responsable</strong></span></p>
        </td>
        <td style="width: 77.95pt; border: solid windowtext 1.0pt; border-left: none; mso-border-left-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt; padding: 0cm 3.5pt 0cm 3.5pt; height: 20.55pt;" valign="top" width="104">
        <p class="MsoNormal" style="text-align: center; line-height: normal; margin: 6.0pt 0cm .0001pt 0cm;" align="center"><span style="font-family: arial, helvetica, sans-serif; font-size: 10pt;">&nbsp;</span></p>
        </td>
        </tr>
        <tr style="mso-yfti-irow: 1; height: 16.25pt;">
        <td style="width: 70.95pt; border: solid windowtext 1.0pt; border-top: none; mso-border-top-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt; padding: 0cm 3.5pt 0cm 3.5pt; height: 16.25pt;" valign="top" width="95">
        <p class="MsoNormal" style="text-align: center; line-height: normal; margin: 6.0pt 0cm .0001pt 0cm;" align="center"><span style="font-family: arial, helvetica, sans-serif; font-size: 10pt;"><strong>Solicitud:</strong></span></p>
        </td>
        <td style="width: 474.85pt; border-top: none; border-left: none; border-bottom: solid windowtext 1.0pt; border-right: solid windowtext 1.0pt; mso-border-top-alt: solid windowtext .5pt; mso-border-left-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt; padding: 0cm 3.5pt 0cm 3.5pt; height: 16.25pt;" colspan="11" valign="top" width="633">
        <p class="MsoNormal" style="margin-bottom: .0001pt; text-align: justify; line-height: normal;">&nbsp;</p>
        </td>
        </tr>
        <tr style="mso-yfti-irow: 2; height: 17.35pt;">
        <td style="width: 70.95pt; border: solid windowtext 1.0pt; border-top: none; mso-border-top-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt; padding: 0cm 3.5pt 0cm 3.5pt; height: 17.35pt;" valign="top" width="95">
        <p class="MsoNormal" style="text-align: center; line-height: normal; margin: 6.0pt 0cm .0001pt 0cm;" align="center"><span style="font-family: arial, helvetica, sans-serif; font-size: 10pt;"><strong>Acci&oacute;n:</strong></span></p>
        </td>
        <td style="width: 474.85pt; border-top: none; border-left: none; border-bottom: solid windowtext 1.0pt; border-right: solid windowtext 1.0pt; mso-border-top-alt: solid windowtext .5pt; mso-border-left-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt; padding: 0cm 3.5pt 0cm 3.5pt; height: 17.35pt;" colspan="11" valign="top" width="633">
        <p class="MsoNormal" style="margin-bottom: .0001pt;">&nbsp;</p>
        </td>
        </tr>
        <tr style="mso-yfti-irow: 3; height: 12.1pt;">
        <td style="width: 203.8pt; border: solid windowtext 1.0pt; border-top: none; mso-border-top-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt; padding: 0cm 3.5pt 0cm 3.5pt; height: 12.1pt;" colspan="3" valign="top" width="272">
        <p class="MsoNormal" style="margin: 6.0pt 0cm .0001pt 0cm;"><span style="font-family: arial, helvetica, sans-serif; font-size: 10pt;"><strong>DENOMINACI&Oacute;N</strong></span></p>
        </td>
        <td style="width: 47.7pt; border-top: none; border-left: none; border-bottom: solid windowtext 1.0pt; border-right: solid windowtext 1.0pt; mso-border-top-alt: solid windowtext .5pt; mso-border-left-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt; padding: 0cm 3.5pt 0cm 3.5pt; height: 12.1pt;" colspan="2" valign="top" width="64">
        <p class="MsoNormal" style="text-align: center; line-height: normal; margin: 6.0pt 0cm .0001pt 0cm;" align="center"><span style="font-family: arial, helvetica, sans-serif; font-size: 10pt;"><strong>PARTIDA</strong></span></p>
        </td>
        <td style="width: 35.25pt; border-top: none; border-left: none; border-bottom: solid windowtext 1.0pt; border-right: solid windowtext 1.0pt; mso-border-top-alt: solid windowtext .5pt; mso-border-left-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt; padding: 0cm 3.5pt 0cm 3.5pt; height: 12.1pt;" valign="top" width="47">
        <p class="MsoNormal" style="text-align: center; line-height: normal; margin: 6.0pt 0cm .0001pt 0cm;" align="center"><span style="font-family: arial, helvetica, sans-serif; font-size: 10pt;"><strong>GEN</strong></span></p>
        </td>
        <td style="width: 35.2pt; border-top: none; border-left: none; border-bottom: solid windowtext 1.0pt; border-right: solid windowtext 1.0pt; mso-border-top-alt: solid windowtext .5pt; mso-border-left-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt; padding: 0cm 3.5pt 0cm 3.5pt; height: 12.1pt;" colspan="2" valign="top" width="47">
        <p class="MsoNormal" style="text-align: center; line-height: normal; margin: 6.0pt 0cm .0001pt 0cm;" align="center"><span style="font-family: arial, helvetica, sans-serif; font-size: 10pt;">&nbsp;<strong>ESPC</strong></span></p>
        </td>
        <td style="width: 75.0pt; border-top: none; border-left: none; border-bottom: solid windowtext 1.0pt; border-right: solid windowtext 1.0pt; mso-border-top-alt: solid windowtext .5pt; mso-border-left-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt; padding: 0cm 3.5pt 0cm 3.5pt; height: 12.1pt;" colspan="2" valign="top" width="100">
        <p class="MsoNormal" style="text-align: center; line-height: normal; margin: 6.0pt 0cm .0001pt 0cm;" align="center"><span style="font-family: arial, helvetica, sans-serif; font-size: 10pt;"><strong>SUB. ESPEC</strong></span></p>
        </td>
        <td style="width: 148.85pt; border-top: none; border-left: none; border-bottom: solid windowtext 1.0pt; border-right: solid windowtext 1.0pt; mso-border-top-alt: solid windowtext .5pt; mso-border-left-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt; padding: 0cm 3.5pt 0cm 3.5pt; height: 12.1pt;" colspan="2" valign="top" width="198">
        <p class="MsoNormal" style="text-align: center; line-height: normal; margin: 6.0pt 0cm .0001pt 0cm;" align="center"><span style="font-family: arial, helvetica, sans-serif; font-size: 10pt;"><strong>VALIDACI&Oacute;N</strong></span></p>
        </td>
        </tr>
        <tr style="mso-yfti-irow: 4; height: 17.85pt;">
        <td style="width: 203.8pt; border: solid windowtext 1.0pt; border-top: none; mso-border-top-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt; padding: 0cm 3.5pt 0cm 3.5pt; height: 17.85pt;" colspan="3" valign="top" width="272">
        <p class="MsoNormal" style="line-height: normal; margin: 6.0pt 0cm 6.0pt 0cm;">&nbsp;</p>
        </td>
        <td style="width: 47.7pt; border-top: none; border-left: none; border-bottom: solid windowtext 1.0pt; border-right: solid windowtext 1.0pt; mso-border-top-alt: solid windowtext .5pt; mso-border-left-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt; padding: 0cm 3.5pt 0cm 3.5pt; height: 17.85pt;" colspan="2" valign="top" width="64">
        <p class="MsoNormal" style="line-height: normal; margin: 6.0pt 0cm 6.0pt 0cm;">&nbsp;</p>
        </td>
        <td style="width: 35.25pt; border-top: none; border-left: none; border-bottom: solid windowtext 1.0pt; border-right: solid windowtext 1.0pt; mso-border-top-alt: solid windowtext .5pt; mso-border-left-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt; padding: 0cm 3.5pt 0cm 3.5pt; height: 17.85pt;" valign="top" width="47">
        <p class="MsoNormal" style="line-height: normal; margin: 6.0pt 0cm 6.0pt 0cm;">&nbsp;</p>
        </td>
        <td style="width: 35.2pt; border-top: none; border-left: none; border-bottom: solid windowtext 1.0pt; border-right: solid windowtext 1.0pt; mso-border-top-alt: solid windowtext .5pt; mso-border-left-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt; padding: 0cm 3.5pt 0cm 3.5pt; height: 17.85pt;" colspan="2" valign="top" width="47">
        <p class="MsoNormal" style="line-height: normal; margin: 6.0pt 0cm 6.0pt 0cm;">&nbsp;</p>
        </td>
        <td style="width: 75.0pt; border-top: none; border-left: none; border-bottom: solid windowtext 1.0pt; border-right: solid windowtext 1.0pt; mso-border-top-alt: solid windowtext .5pt; mso-border-left-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt; padding: 0cm 3.5pt 0cm 3.5pt; height: 17.85pt;" colspan="2" valign="top" width="100">
        <p class="MsoNormal" style="line-height: normal; margin: 6.0pt 0cm 6.0pt 0cm;">&nbsp;</p>
        </td>
        <td style="width: 148.85pt; border-top: none; border-left: none; border-bottom: solid windowtext 1.0pt; border-right: solid windowtext 1.0pt; mso-border-top-alt: solid windowtext .5pt; mso-border-left-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt; padding: 0cm 3.5pt 0cm 3.5pt; height: 17.85pt;" colspan="2" valign="top" width="198">
        <p class="MsoNormal" style="line-height: normal; margin: 6.0pt 0cm 6.0pt 0cm;">&nbsp;</p>
        </td>
        </tr>
        <tr style="mso-yfti-irow: 5; mso-yfti-lastrow: yes; height: 14.7pt;">
        <td style="width: 203.8pt; border: solid windowtext 1.0pt; border-top: none; mso-border-top-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt; padding: 0cm 3.5pt 0cm 3.5pt; height: 14.7pt;" colspan="3" valign="top" width="272">
        <p class="MsoNormal" style="line-height: normal; margin: 6.0pt 0cm 6.0pt 0cm;"><span style="font-family: arial, helvetica, sans-serif; font-size: 10pt;"><strong>Observaciones:</strong></span></p>
        </td>
        <td style="width: 342.0pt; border-top: none; border-left: none; border-bottom: solid windowtext 1.0pt; border-right: solid windowtext 1.0pt; mso-border-top-alt: solid windowtext .5pt; mso-border-left-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt; padding: 0cm 3.5pt 0cm 3.5pt; height: 14.7pt;" colspan="9" valign="top" width="456">
        <p class="MsoNormal" style="line-height: normal; margin: 6.0pt 0cm 6.0pt 0cm;">&nbsp;</p>
        </td>
        </tr>
        </tbody>
        </table>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <table class="MsoTableGrid" style="border-collapse: collapse; border: none; mso-border-alt: solid black .5pt; mso-border-themecolor: text1; mso-yfti-tbllook: 1184; mso-padding-alt: 0cm 5.4pt 0cm 5.4pt;" border="1" cellspacing="0" cellpadding="0">
        <tbody>
        <tr style="mso-yfti-irow: 0; mso-yfti-firstrow: yes; height: 12.55pt;">
        <td style="width: 191.4pt; border: solid black 1.0pt; mso-border-themecolor: text1; border-bottom: solid windowtext 1.0pt; mso-border-alt: solid black .5pt; mso-border-bottom-alt: solid windowtext .5pt; padding: 0cm 5.4pt 0cm 5.4pt; height: 12.55pt;" valign="top" width="255">
        <p class="MsoNormal" style="margin-bottom: .0001pt; text-align: center; line-height: normal;" align="center"><span style="font-family: arial, helvetica, sans-serif; font-size: 10pt;"><strong>Elaborado:</strong></span></p>
        </td>
        <td style="width: 177.2pt; border-top: solid black 1.0pt; mso-border-top-themecolor: text1; border-left: none; border-bottom: solid windowtext 1.0pt; border-right: solid black 1.0pt; mso-border-right-themecolor: text1; mso-border-left-alt: solid black .5pt; mso-border-left-themecolor: text1; mso-border-alt: solid black .5pt; mso-border-themecolor: text1; mso-border-bottom-alt: solid windowtext .5pt; padding: 0cm 5.4pt 0cm 5.4pt; height: 12.55pt;" valign="top" width="236">
        <p class="MsoNormal" style="margin-bottom: .0001pt; text-align: center; line-height: normal;" align="center"><span style="font-family: arial, helvetica, sans-serif; font-size: 10pt;"><strong>Verificado:</strong></span></p>
        </td>
        <td style="width: 177.2pt; border-top: solid black 1.0pt; mso-border-top-themecolor: text1; border-left: none; border-bottom: solid windowtext 1.0pt; border-right: solid black 1.0pt; mso-border-right-themecolor: text1; mso-border-left-alt: solid black .5pt; mso-border-left-themecolor: text1; mso-border-alt: solid black .5pt; mso-border-themecolor: text1; mso-border-bottom-alt: solid windowtext .5pt; padding: 0cm 5.4pt 0cm 5.4pt; height: 12.55pt;" valign="top" width="236">
        <p class="MsoNormal" style="margin-bottom: .0001pt; text-align: center; line-height: normal;" align="center"><span style="font-size: 10pt;"><strong><span style="font-family: arial, helvetica, sans-serif;">Aprobado:</span></strong></span></p>
        </td>
        </tr>
        <tr style="mso-yfti-irow: 1; mso-yfti-lastrow: yes; height: 82.0pt;">
        <td style="width: 191.4pt; border: solid black 1.0pt; mso-border-themecolor: text1; border-top: none; mso-border-top-alt: solid windowtext .5pt; mso-border-alt: solid black .5pt; padding: 0cm 5.4pt 0cm 5.4pt; height: 82.0pt;" valign="top" width="255">
        <p class="MsoNormal" style="margin-bottom: .0001pt; line-height: normal;">&nbsp;</p>
        </td>
        <td style="width: 177.2pt; border-top: none; border-left: none; border-bottom: solid black 1.0pt; mso-border-bottom-themecolor: text1; border-right: solid black 1.0pt; mso-border-right-themecolor: text1; mso-border-top-alt: solid windowtext .5pt; mso-border-left-alt: solid black .5pt; mso-border-left-themecolor: text1; mso-border-alt: solid black .5pt; mso-border-themecolor: text1; padding: 0cm 5.4pt 0cm 5.4pt; height: 82.0pt;" valign="top" width="236">
        <p class="MsoNormal" style="margin-bottom: .0001pt; line-height: normal;">&nbsp;</p>
        </td>
        <td style="width: 177.2pt; border-top: none; border-left: none; border-bottom: solid black 1.0pt; mso-border-bottom-themecolor: text1; border-right: solid black 1.0pt; mso-border-right-themecolor: text1; mso-border-top-alt: solid windowtext .5pt; mso-border-left-alt: solid black .5pt; mso-border-left-themecolor: text1; mso-border-alt: solid black .5pt; mso-border-themecolor: text1; padding: 0cm 5.4pt 0cm 5.4pt; height: 82.0pt;" valign="top" width="236">
        <p class="MsoNormal" style="margin-bottom: .0001pt; line-height: normal;">&nbsp;</p>
        </td>
        </tr>
        </tbody>
        </table>
        </body>
    </html>
    `;
  }

  fillAdjudicacion(user?) {
    if (user == undefined) user = this.user;
    this.code = 15;
    let fecha = new Date();
    return this.content = `
    <!DOCTYPE html>
    <html>
    <head>
    </head>
      <body style="font-family:arial;">
        <table class="MsoNormalTable" style="width: 100%; border-collapse: collapse; border: none; mso-border-alt: solid windowtext 0pt; mso-padding-alt: 0cm 3.5pt 0cm 3.5pt; mso-border-insideh: 0pt solid windowtext; mso-border-insidev: 0pt solid windowtext;" border="0" width="100%" cellspacing="0" cellpadding="0">
          <tbody class="mceNonEditable" style="font-size:13px;">
            <tr style="mso-yfti-irow: 0; mso-yfti-firstrow: yes; height: 56.25pt;">
            <td style="width: 272.25pt; border: solid windowtext 0pt; mso-border-alt: solid windowtext 0pt; padding: 0cm 3.5pt 0cm 3.5pt; height: 56.25pt;" rowspan="2" valign="top" width="363">
            <p class="MsoNormal" style="text-align: center;">Rep&uacute;blica Bolivariana de Venezuela</p>
            <p class="MsoNormal" style="text-align: center;"><img style="display: block; margin-left: auto; margin-right: auto;" src="${this.escudo}" /></p>
            <p class="MsoNormal" style="text-align: center;">Comisión Única de Contrataciones</p>
            <p class="MsoNormal" style="margin-bottom: .0001pt; line-height: normal;">&nbsp;</p>
            </td>
            <td style="width: 273.55pt; border: solid windowtext 0pt; border-left: none; mso-border-left-alt: solid windowtext 0pt; mso-border-alt: solid windowtext 0pt; padding: 0cm 3.5pt 0cm 3.5pt; height: 56.25pt;" valign="top" width="365">
            <p class="MsoNormal" style="text-align: center;" align="right">Gobernaci&oacute;n del Estado Bolivariano de Gu&aacute;rico</p>
            <p class="MsoNormal" style="text-align: center;"><img style="display: block; margin-left: auto; margin-right: auto;" src="${this.logo}" /></p>
            </td>
            </tr>
            <tr style="mso-yfti-irow: 1; mso-yfti-lastrow: yes; height: 12.35pt;">
            <td style="width: 273.55pt; border-top: none; border-left: none; border-bottom: solid windowtext 0pt; border-right: solid windowtext 0pt; mso-border-top-alt: solid windowtext 0pt; mso-border-left-alt: solid windowtext 0pt; mso-border-alt: solid windowtext 0pt; padding: 0cm 3.5pt 0cm 3.5pt; height: 12.35pt;" valign="top" width="365">
            <p class="MsoNormal" style="margin-bottom: .0001pt; text-align: right; line-height: normal;" align="right"><strong><!--date--></strong></p>
            </td>
            </tr>
          </tbody>
        </table>
        <div>
        <br>
        <p>CUERPO</p>
        <br>
        </div> 
        <table style="border-collapse: collapse; width: 100%; border="0" >
          <tbody>
            <tr>
            <td style="width: 33.3%; font-family: arial;">
              <!--g-seal-->
            </td>
            <td style="width: 33.3%; font-family: arial; text-align:center;" class="mceNonEditable">
               <!--g-sign--> 
              <span style="font-size:9px; font-weight:bolder;">
              RICARDO JOSÉ SÁNCHEZ MENDOZA
              SECRETARIO DE FINANZAS DEL ESTADO BOLIVARIANO 
              DE GUÁRICO DELEGACIÓN DE FIRMA SEGÚN GACETA 
              EXTRAORDINARIA N. ________ DE FECHA 28/11/2017
              </span>
            </td>
            <td align="right" style="width: 33.3%; font-family: arial;" class="mceNonEditable">
              <!--qrcode-->
            </td>
            </tr>
          </tbody>
        </table>
      </body>
      </html>
    `;
  }

  fillOficioGob(user?) {
    if (user == undefined) user = this.user;
    this.code = 17;
    return this.content = `
  <!DOCTYPE html>
    <html>
    <head>
    </head>
    <body>
      <table style="width: 100%; border-collapse: collapse;" border="0">
        <tbody>
          <tr>
            <td style="width: 50%;">
              <p style="text-align: center;">Rep&uacute;blica Bolivariana de Venezuela</p>
              <p style="text-align: center;">Gobernaci&oacute;n del Estado Bolivariano de Gu&aacute;rico</p>
              <p style="text-align: center;">
                <img style="display: block; margin-left: auto; margin-right: auto;" src="${this.escudo}" />
              </p>
              <p style="text-align: center;">Despacho del Gobernador</p>
            </td>
            <td style="width: 50%;">
              <p style="text-align: right;">
                <img style="display: block; margin-left: auto; margin-right: auto;" src="${this.logo}" />
              </p>
            </td>
          </tr>
        </tbody>
      </table>
      <div>
          <table style="border-collapse: collapse; width: 100%;" border="0">
          <tbody>
          <tr>
          <td style="width: 50%; font-family: arial;">
          <p style="font-size:12px;" class="mceNonEditable"><strong><!--correlativo--></strong></p>
          </td>
          <td style="width: 50%; font-family: arial;">
          <p style="font-size:12px; text-align:right;"><strong>San Juan de los Morros,</strong></p>
          <p style="font-size:12px; text-align:right;"><strong><!--date--></strong></p>
          </td>
          </tr>
          </tbody>
          </table>
        </div>
      <table style="border-collapse: collapse; width: 100%;" border="0">
        <tbody>
          <tr>
            <td style="width: 100%;">
              &nbsp;
              &nbsp;
              &nbsp;
              <p class="MsoNormal" style="text-align: justify; line-height: 115%;">CUERPO</p>
              &nbsp;
              &nbsp;
              &nbsp;
              &nbsp;
              &nbsp;
              &nbsp;
              &nbsp;
              &nbsp;
              &nbsp;
            </td>
          </tr>
          <div>
            <table style="border-collapse: collapse; width: 100%;" border="0">
              <tbody>
                <tr>
                  <td style="width: 66.6%; font-family: arial;">
                    <div align="center">
                      <p class="MsoNormal" style="display:table-cell;" align="center">
                        <!--g-seal-->
                      </p>
                      <p class="MsoNormal" style="display:table-cell;" align="center">
                        <!--g-sign-->
                      </p>
                    </div>
                    <p class="MsoNormal" style="margin-bottom: .0001pt; text-align: center; line-height: normal;" align="center">
                      <strong>
                        <span style="font-family: 'Times New Roman','serif';">José Manuel Vásquez Aranguren</span>
                      </strong>
                    </p>
                    <p class="MsoNormal" style="margin-bottom: .0001pt; text-align: center; line-height: normal;" align="center">
                        <span style="font-family: 'Times New Roman','serif';">Gobernador del Estado Bolivariano de Guárico</span>
                    </p>
                    <p class="MsoNormal" style="text-align: center;" align="center">
                      <span style="font-family: 'Times New Roman','serif';">
                        G. O. del Estado Guárico Extraordinaria Nº1, del 20/10/2017.
                      </span>
                    </p>
                    <p class="MsoNormal" style="text-align: center;" align="center">
                      <span style="font-family: 'Times New Roman','serif';">
                       Decreto Nº 01, del 20/10/2017,
                      </span>
                    </p>
                    <p class="MsoNormal" style="text-align: center;" align="center">
                      <span style="font-family: 'Times New Roman','serif';">
                        G. O. del Estado Guárico Extraordinaria Nº 01.
                      </span>
                    </p>
                    <span style="font-size: 9.0pt; mso-bidi-font-size: 12.0pt; line-height: 115%; font-family: 'Times New Roman','serif'; mso-fareast-font-family: Calibri; mso-ansi-language: ES-VE; mso-fareast-language: EN-US; mso-bidi-language: AR-SA;">//</span>
                  </td>
                  <td align="right" style="width: 33.3%; font-family: arial;" class="mceNonEditable">
                      <!--qrcode-->
                  </td>
              </tbody>
            </table>
          </div>
        </tbody>
      </table>
    </body>
    </html>
    `;
  }


  upperCase(w) {
    return w.toUpperCase();
  }

  ngOnInit() {
  }
  content;
}