import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './material/material.module';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material';

//COMPONENTES
import { AppComponent } from './app.component';
import { CreateUserComponent } from './components/users/create-user/create-user.component';
import { NotfoundComponent } from './components/errors/notfound/notfound.component';
import { PermisionsComponent } from './components/errors/permisions/permisions.component';
import { LoginUserComponent } from './components/users/login-user/login-user.component';
import { HomeComponent } from './components/home/home/home.component';
import { WriteComponent } from './components/write/write.component';
import { ReadComponent } from './components/read/read.component';
import { FormatSheetComponent } from './components/write/format-sheet/format-sheet.component';
import { SendToComponent } from './components/write/send-to/send-to.component';
import { UploadComponent } from './components/write/upload/upload.component';


//LIBRERIAS
import { JwtHelperService, JwtModule } from '@auth0/angular-jwt';
import { NgxQRCodeModule } from 'ngx-qrcode2';
import { FilterPipeModule } from 'ngx-filter-pipe';
import { SweetAlert2Module } from '@toverux/ngx-sweetalert2';
import { NgxUploaderModule } from 'ngx-uploader';
import {NgxPaginationModule} from 'ngx-pagination';
import { CookieService } from 'ngx-cookie-service';

//GUARDS
import { AuthGuard } from './guards/auth-guard.guard';

//DOCUMENTOS
import { OficioComponent } from './documents/oficio/oficio.component';
import { PcComponent } from './documents/pc/pc.component';
import { DecretoComponent } from './documents/decreto/decreto.component';

//INTERCEPTORS
import { UnauthorizedInterceptor } from './services/auth/unauthorized.interceptor';

//Editor
import { EditorModule } from '@tinymce/tinymce-angular';

//PIPES
import { CapitalizePipe } from './pipes/capitalize.pipe';
import { MailInfoComponent } from './components/home/mail-info/mail-info.component';

const appRoutes: Routes = [
  { path: 'login', component: LoginUserComponent},
  { path: 'create-user', component: CreateUserComponent, canActivate: [AuthGuard]},
  { path: 'not-authorized', component: PermisionsComponent},
  { path: 'bandeja/:type', component: HomeComponent, canActivate: [AuthGuard]},
  { path: '', component: HomeComponent, canActivate: [AuthGuard]},
  { path: 'redactar', component: WriteComponent, canActivate: [AuthGuard]},
  { path: 'editar', component: WriteComponent, canActivate: [AuthGuard]},
  { path: 'leer/:mail', component: ReadComponent, canActivate: [AuthGuard]},
  { path: '**', component: NotfoundComponent}
];

export function tokenGetter() {
  return localStorage.getItem('token');
}

@NgModule({
  declarations: [
    AppComponent,
    CreateUserComponent,
    NotfoundComponent,
    PermisionsComponent,
    LoginUserComponent,
    HomeComponent,
    OficioComponent,
    WriteComponent,
    PcComponent,
    FormatSheetComponent,
    SendToComponent,
    ReadComponent,
    DecretoComponent,
    CapitalizePipe,
    UploadComponent,
    MailInfoComponent
  ],
  imports: [
    BrowserModule,
    NgxUploaderModule,
    BrowserAnimationsModule,
    MaterialModule,
    FormsModule,
    MatNativeDateModule,
    ReactiveFormsModule,
    HttpClientModule,
    EditorModule,
    NgxQRCodeModule,
    FilterPipeModule,
    NgxPaginationModule,
    SweetAlert2Module.forRoot(),
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        whitelistedDomains: ['*']
      }
    }),
    RouterModule.forRoot(
      appRoutes,
      { enableTracing: false }
    )    
  ],
  entryComponents : [
    FormatSheetComponent,
    SendToComponent,
    UploadComponent,
    MailInfoComponent
  ],
  providers: [
   JwtHelperService,
   { provide: HTTP_INTERCEPTORS, useClass: UnauthorizedInterceptor, multi: true },
   { provide: MAT_DATE_LOCALE, useValue: 'es-VE'},
   CookieService
   ],
  bootstrap: [AppComponent]
})
export class AppModule { }
