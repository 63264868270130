import { Component, Inject } from '@angular/core';
import { MailService } from '../../../services/mail/mail.service';
import { MatDialogRef, MAT_DIALOG_DATA,  } from '@angular/material';
import { SwalComponent } from '@toverux/ngx-sweetalert2';

export enum OPERATION_TYPE {
  SEND,
  ASSIGN,
  RESEND
}

@Component({
  selector: 'app-send-to',
  templateUrl: './send-to.component.html',
  styleUrls: ['./send-to.component.scss']
})
export class SendToComponent {

  maxReceivers : number = -1;
  type : OPERATION_TYPE;
  task : string;
  sending : boolean = false;
  loading : boolean = false;

  constructor(
    public dialogRef: MatDialogRef<SendToComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public mailService : MailService
    ) {  
    this.getReceivers();
    this.maxReceivers = data.maxReceivers || -1;
    this.type = data.operation;
  }

  resend(){
    this.sending = true;
    let _rec = this.receivers.map(function (v){
      return v.id;
    });

    this.mailService.resendMail({mail_id : this.data.mail, receivers : _rec})
      .subscribe(res => {
        this.sending = false;
        this.dialogRef.close(true);
      }, error => {
        this.sending = false;

        let swal = new SwalComponent({
          type: 'error',
          title: "Error al enviar.",
          html: ' <br>¿Quiere intentar reenviar el mensaje nuevamente?</span>',
          showCancelButton: true,
          cancelButtonText: 'No',
          confirmButtonText: 'Si'
        });
        swal.show()
        .then((resend) => {
          if (resend.value) {
            this.resend();
          }else{
            this.dialogRef.close(false);
          }
        });
      });    
  }

  send(){
    let _rec = this.receivers.map(function (v){
      return v.id;
    });
    
    this.sending = true;
    this.mailService.sendMail({
      data      : this.data.data,
      receivers : _rec,
      subject   : this.data.subject,
      status : (this.data.code == 13) ? 2 : this.data.status,
      mail_type : this.data.code,
      attachments: this.filterAttachments(),
      sender : (this.data.code == 13) ? 2 : this.data.sender
    })
    .subscribe(res => {
      this.sending = false;
      this.dialogRef.close(true);
    }, error => {
      this.sending = false;
      let msg = '<span style="font-family:arial;">';
      if (error == "Bad Request") {
        msg += 'Verfique el campo <strong>asunto</strong>.';
      }
      let swal = new SwalComponent({
        type: 'error',
        title: "Error al enviar.",
        html: msg+' <br>¿Quiere intentar enviar el mensaje nuevamente?</span>',
        showCancelButton: true,
        cancelButtonText: 'No',
        confirmButtonText: 'Si'
      });
      swal.show()
      .then((resend) => {
        if (resend.value) {
          this.send();
        }else{
          this.dialogRef.close(false);
        }
      });
    });
  }

  assign(){
    let _rec = this.receivers.map(function (v){
      return v.id;
    });
    this.mailService.assignTo({
      assigned_to : _rec[0],
      mail_id : this.data.data,
      task : this.task || ""
    }).subscribe(res => {
      this.dialogRef.close(res);
    }, error => {
      if (error == "Bad Request"){
        new SwalComponent({text: 'Debe llenar el campo "Razón por la cual asigna".', timer:2000})
        .show().then(() => {
          document.getElementById('task').focus();
        });
      }
    });    
  }

  filterAttachments(){
    let fa = [];
    for (var i = this.data.attachments.length - 1; i >= 0; i--) {
      fa.push(this.data.attachments[i].response.success.id);
    }
    return fa;
  }

  sortReceivers(a, b){
    let orgA = a.organism.toLowerCase();
    let orgB = b.organism.toLowerCase();
    if (orgA > orgB) return 1;
    if (orgA < orgB) return -1;
    return 0;
  }

  getReceivers(pass=this.data.pass){
    this.loading = true;
    this.mailService.getReceivers(pass)
    .subscribe(rec => {
      this.loading = false;
      this.contacts = rec;
      localStorage.setItem('receivers', JSON.stringify(rec));    
    }, error => {
      this.loading = false;
      let swal = new SwalComponent({
        type: 'error',
        title: "Error al obtener contactos.",
        text: "¿Quiere intentarlo nuevamente?",
        showCancelButton: true,
        cancelButtonText: 'No',
        confirmButtonText: 'Si'
      });
      swal.show()
      .then((resend) => {
        if (resend.value) {
          this.getReceivers(pass);
        }else{
          this.dialogRef.close(false);
        }
      });
    });
  
  }

  pushReceivers(c){
    if (this.maxReceivers == this.receivers.length) return;
  	let el = this.contacts.indexOf(c);
  	this.receivers.push(this.contacts.splice(el, 1)[0]);
  	this.receivers.sort(this.sortReceivers);
  }

  quitReceivers(r){
  	let el = this.receivers.indexOf(r);
  	this.contacts.push(this.receivers.splice(el, 1)[0]);
  	this.contacts.sort(this.sortReceivers);
  }

  filter = {organism : ''};

  receivers = [];

  contacts : any;

}
