import { Component } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Router, NavigationStart, NavigationEnd } from '@angular/router';
import { AuthServiceService } from './services/auth/auth-service.service';
import { MailService } from './services/mail/mail.service';
import { CookieService } from 'ngx-cookie-service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  opened: boolean = false;
  phone: boolean = false;
  hideOnLogin : boolean = false;
  isSmallScreen:  any;
  name : string = "";
  doctype = "oficio";
  opts : Array<{nombre: string, icon : string, link:string, not_readed: number}>;

  public constructor(
  	public breakpointObserver : BreakpointObserver,
    private router : Router,
    private authService : AuthServiceService,
    private mailService : MailService,
    private cs: CookieService
    ){

    this.buildMenu();
    this.getUnread();
    
    router.events.subscribe(event => {
      if(event instanceof NavigationStart || event instanceof NavigationEnd){
        if (event.url == "/login" || event.url == "/" && !this.authService.isAuthenticated()) {
          this.hideOnLogin = true;
          this.opened = false;
        }else{
          let user = JSON.parse(localStorage.getItem("user"));
          this.name = user.firstname + " " + user.lastname;
          this.hideOnLogin = false;
          this.opened = true;
        }
      }
    });
  }

  getUnread(){
    this.mailService.notReaded.subscribe(data => {
      
      switch (data.tray) {
        case "recibidas":
          this.opts[0].not_readed = 0;
          break;
        case "enviadas":
          this.opts[1].not_readed = 0;
          break;        
        case "archivadas":
          this.opts[5].not_readed = 0;
          break;
        case "borradores":
          this.opts[4].not_readed = 0;
          break;
        case "aprobadas":
          this.opts[3].not_readed = 0;
          break;
        case "por aprobar":
          this.opts[2].not_readed = 0;
          break;    
        case "rechazadas":
          this.opts[7].not_readed = 0;
          break;                    
        case "asignadas":
          this.opts[6].not_readed = 0;
          break;   
        default:
          this.opts[0].not_readed = 0;
          break;
      }
    }, error => {
      console.log(error);
    });
  }

  logout(){
    this.authService.logout().subscribe(res => {
      this.cs.delete('token');
      this.router.navigate(["login"]);
    }, error => {
      this.router.navigate(["login"]);
    });
    this.hideOnLogin = true;
    this.opened = false;      
    this.router.navigate(["login"]);
  }

  lookForScreenChanges(){
  const layoutChanges = this.breakpointObserver.observe([
  	'(max-width: 599px)'
	]);

	layoutChanges.subscribe(result => {
    console.log(result);
	  this.phone, this.opened = !(result.matches);
	});
  }

  buildMenu(){
    let menu = [];
    let trays = JSON.parse(localStorage.getItem('user')) || null;
    let _trays = trays ? trays.trays : {recibidas : 0, asignadas : 0};
    menu.push(
      {name : "Recibidas",   icon : "archive",    link : "bandeja/recibidas",  not_readed : _trays.recibidas || 0},
      {name : "Enviadas",    icon : "unarchive",  link : "bandeja/enviadas",   not_readed : 0},
      {name : "Por aprobar", icon : "check",      link : "bandeja/por-aprobar", not_readed : 0},      
      {name : "Aprobadas",   icon : "done_all",   link : "bandeja/aprobadas", not_readed : 0},
      {name : "Archivadas",  icon : "inbox",      link : "bandeja/archivadas", not_readed : 0},
      {name : "Asignadas",   icon : "assignment", link : "bandeja/asignadas",  not_readed : 0},
      {name : "Diferidas",   icon : "access_time",link : "bandeja/diferidas",  not_readed : 0},
      {name : "Rechazadas",  icon : "remove_circle_outline",link : "bandeja/rechazadas",  not_readed : 0},
      {name : "Redactadas",  icon : "create",     link : "bandeja/redactadas", not_readed : 0}
    );
    this.opts = menu;
  }

}
