import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { JwtHelperService } from '@auth0/angular-jwt';
import { URL } from '../const';

@Injectable({
  providedIn: 'root'
})
export class AuthServiceService {

  private token : string;
  private authHeaders : HttpHeaders;
  
  constructor(private http : HttpClient, private jwt : JwtHelperService) { 

  }

  //Verifica si el usuario esta autenticado.
  public isAuthenticated(){
  	const token = localStorage.getItem('token');
  	const helper = new JwtHelperService();
    if (!token) {
  	  return false;	
  	}else{
  		return !helper.isTokenExpired(token);
  	}
  }

  //Leer organismos.
  public getOrganisms(){
  	return this.http.get(URL+'/organisms');
  }

  //Leer cargos.
  public getCharges(){
  	return this.http.get(URL+'/charges');
  }

  public getPermissions(){
   return this.http.get(URL+'/permissions'); 
  }

  //Crear un usuario.
  public createUser(user:{}){
  	return this.http.post(URL+'/register', user);
  }

  //Iniciar sesion.
  public login(user:{}){
    return this.http.post(URL+'/login', user);
  }

  //Cerrar sesion.
  public logout(){
    
    let _headers = this.setHeader();
    localStorage.clear();
    return this.http.get(URL+'/logout', {
      headers : _headers
    });
  
  }

  //Setear request headers.
  private setHeader() : HttpHeaders{

    let token = localStorage.getItem('token');
    let authHeaders;
    if (token) {
      return authHeaders = new HttpHeaders({
        'Authorization' : 'Bearer ' + token
      });
    }

    return new HttpHeaders({});

  }

}
