import { Component, OnInit, Inject } from '@angular/core';
import { MailService } from '../../../services/mail/mail.service';
import { MAT_DIALOG_DATA, } from '@angular/material';

@Component({
  selector: 'app-mail-info',
  templateUrl: './mail-info.component.html',
  styleUrls: ['./mail-info.component.scss']
})
export class MailInfoComponent implements OnInit {

  info : any[];

  constructor(
    private mailService: MailService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      console.log(this.data);
  }

  getInfo() {
    this.mailService.sendToInfo(this.data.id)
      .subscribe((res:any[]) => {
        this.info = res;
      }, error => {
        console.log(error);
    });
  }

  ngOnInit() {
    this.getInfo();
  }

}
