import { Component } from '@angular/core';
import { NgxQRCodeComponent } from 'ngx-qrcode2';
import { Utils } from '../utils';
import { AbstractDocument } from '../abstract-doc';


@Component({
	selector: 'app-pc',
	templateUrl: './pc.component.html',
	styleUrls: ['./pc.component.scss']
})
export class PcComponent extends AbstractDocument {

	constructor() {
		super();
		this.acronym = 'PC';
	}

	gen() {
		let qr = new NgxQRCodeComponent(null);
		qr.value = "http://correspondencia.guarico.gob.ve";
		qr.elementType = "url";
		qr.errorCorrectionLevel = "L";
		return qr;
	}

	fillPc(user?) {
		if (user == undefined) user = this.user;
		console.log(user);
		this.code = 2;
		return this.content = `
  	<!DOCTYPE html>
	<html>
		<style>
			.tr{
				background:red;
			}
			.td{
				border: solid black 1px;
				text-align:justify;
			}
			table{
				table-layout: fixed;
			}
		</style>
		<body>
			<span style="float:right; color: red;"><!--decision--></span>
			
			<table style="border-collapse: collapse; width: 100%;" border="0" class="mceNonEditable">
			<tbody>
			<tr>
			<td style="width: 10%; text-align: center;">
			<p>&nbsp;</p>
			<img style=" margin-left: auto; margin-right: auto;" src="${this.escudo}" />
			<pre style="text-align: center;">Rep&uacute;blica Bolivariana de Venezuela<br>Gobernaci&oacute;n del Estado Bolivariano de Gu&aacute;rico<br><br></pre>
			</td>
			<td style="width: 90%;">
			<p class="MsoBodyText3"><span lang="ES" style="font-size: 11.0pt; font-family: 'Arial Black','sans-serif'; mso-bidi-font-family: Arial;">PUNTO DE CUENTA DE CRÉDITO ADICIONAL AL CIUDADANO GOBERNADOR DEL ESTADO &nbsp;BOLIVARIANO DE GU&Aacute;RICO</span></p>
			</td>
			</tr>
			</tbody>
			</table>

		      <table style="width: 100%; border-collapse: collapse; font-weight:bold;">

		      <tr>
		      <td style="width: 25%; font-family: arial; margin:0; border:1px solid black;" class="mceNonEditable">
		        <p style="font-size:12px;"><strong><!--correlativo--></strong></p>
		      </td>
		      <td style="width: 50%; font-family: arial; margin:0; border:1px solid black; text-align:left;" class="mceNonEditable">
		        <p style="font-size:12px;"><strong>CIUDADANO(A):</strong></p>
      			<p style="font-size:12px;"><strong>${this.upperCase(user.firstname)} ${this.upperCase(user.lastname)}</strong></p>
       			<p style="font-size:12px;"><strong>${this.upperCase(user.charge_h)} </strong></p>
		      </td>
		      <td style="width: 25%; font-family: arial; margin:0; border:1px solid black; text-align:right" class="mceNonEditable">
		        <p style="font-size:12px;"><strong><!--date--></strong></p>
		      </td>
		      </tr>
		      </table>

			<br>
			<br>
			<div align="center">
				<div style="border-collapse: collapse; width: 100%;" border="1">
					<div>
						<div class="td" style=" border: solid 1px; background: red; page-break-inside: avoid;">
							<p style="font-size: 9.0pt; font-family: 'Arial','sans-serif'; color: white;"><strong>ASUNTO:</strong></p>
						</div>
					</div>
					<div>
						<div class="td">
							<p>&nbsp;</p>
						</div>
					</div>
					<div>
						<div class="td" style="border: solid 1px;  background: red; page-break-inside: avoid" >
							<p style="font-size: 9.0pt; font-family: 'Arial','sans-serif'; color: white;"><strong>ARGUMENTACIÓN:</strong></p>
						</div>
					</div>
					<div>
						<div class="td">
							<p>&nbsp;</p>
						</div>
					</div>
					<div>
						<div class="td" style="border: solid 1px;  background: red; page-break-inside: avoid;">
							<p style="font-size: 9.0pt; font-family: 'Arial','sans-serif'; color: white;"><strong>PROPUESTA:</strong></p>
						</div>
					</div>
					<div>
						<div class="td">
							<p>&nbsp;</p>
						</div>
					</div>
					<div>
						<div class="td" style="border: solid 1px;  background: red; page-break-inside: avoid;">
							<p style="font-size: 9.0pt; font-family: 'Arial','sans-serif'; color: white;"><strong>COMENTARIOS DEL CIUDADANO GOBERNADOR:</strong></p>
						</div>
					</div>
					<div>
						<div class="td" style="border: solid 1px;" class="mceNonEditable">
							&nbsp;<!--comments-->
						</div>
					</div>
				</div>
			</div>
			<br>
		    <div>
		      <table style="border-collapse: collapse; width: 100%;" border="0" class="mceNonEditable">
		      <tbody>
		      <tr>
		      <td style="width: 33.3%; font-family: arial; text-align: center;" class="mceNonEditable">
		        <!--g-seal-->
		      </td>
		      <td style="width: 33.3%; font-family: arial; text-align: center;" class="mceNonEditable">
						<!--g-sign-->
						<p style="font-size:12px">G.O.E Nº 01 DECRETO Nº 01 DE FECHA 20/10/17</p>
		      </td>
		      <td style="width: 33.3%; font-family: arial; text-align: right;" class="mceNonEditable">
		        <!--qrcode-->
		      </td>
		      </tr>
		      </tbody>
		      </table>
			</div> 
		</body>
	</html>

  	`;
	}
	fillPcInfo(code, title, sub1, sub2, sub3, user?) {
		if (user == undefined) user = this.user;
		this.code = code;
		return this.content = `
  	<!DOCTYPE html>
	<html>
		<style>
			.tr{
				background:red;
			}
			.td{
				border: solid black 1px;
				text-align:justify;

			}
		</style>	
		<body>
		<span style="float:right; color: red;"><!--decision--></span>
			<table style="border-collapse: collapse; width: 100%;" border="0" class="mceNonEditable">
			<tbody>
			<tr>
			<td style="width: 10%; text-align: center;">
			<p>&nbsp;</p>
			<p><img style=" margin-left: auto; margin-right: auto;" src="${this.escudo}" /></p>
			<pre style="text-align: center;">Rep&uacute;blica Bolivariana de Venezuela<br />Gobernaci&oacute;n del Estado Bolivariano de Gu&aacute;rico<br /><br /></pre>
			</td>
			<td style="width: 90%;">
			<p class="MsoBodyText3"><span lang="ES" style="font-size: 11.0pt; font-family: 'Arial Black','sans-serif'; mso-bidi-font-family: Arial;">${title} PARA EL CIUDADANO GOBERNADOR DEL ESTADO &nbsp;BOLIVARIANO DE GU&Aacute;RICO</span></p>
			</td>
			</tr>
			</tbody>
			</table>
			</table>
			<div>
		      <table style="border-collapse: collapse; width: 100%; font-weight:bold;" border="1">
		      <tr>
		      <td style="width: 25%; font-family: arial;" class="mceNonEditable">
		        <p style="font-size:12px;"><strong><!--correlativo--></strong></p>
		      </td>
		      <td style="width: 50%; font-family: arial; text-align:left;" class="mceNonEditable">
		        <p style="font-size:12px;"><strong>CIUDADANO(A):</strong></p>
      			<p style="font-size:12px;"><strong>${this.upperCase(user.firstname)} ${this.upperCase(user.lastname)}</strong></p>
       			<p style="font-size:12px;"><strong>${this.upperCase(user.charge_h)} </strong></p>
		      </td>
		      <td style="width: 25%; font-family: arial; text-align:right" class="mceNonEditable">
		        <p style="font-size:12px;"><strong><!--date--></strong></p>
		      </td>
		      </tr>
		      </table>
		    </div>	  
			</table>
			<br>
			<br>
			<div align="center">
				<div style="border-collapse: collapse; width: 100%;" border="1">
					<div>
						<div class="td" style=" border: solid 1px; background: red; page-break-inside: avoid;">
							<p style="font-size: 9.0pt; font-family: 'Arial','sans-serif'; color: white; text-align:left;"><strong>${sub1}:</span></strong></p>
						</div>
					</div>
					<div>
						<div class="td">
							<p>&nbsp;</p>
						</div>
					</div>
					<div>
						<div class="td" style="border: solid 1px;  background: red; page-break-inside: avoid" >
							<p style="font-size: 9.0pt; font-family: 'Arial','sans-serif'; color: white; text-align:left;"><strong>${sub2}:</strong></p>
						</div>
					</div>
					<div>
						<div class="td">
							<p>&nbsp;</p>
						</div>
					</div>
					<div>
						<div class="td" style="border: solid 1px;  background: red; page-break-inside: avoid;">
							<p style="font-size: 9.0pt; font-family: 'Arial','sans-serif'; color: white; text-align:left;"><strong>${sub3}:</strong></p>
						</div>
					</div>
					<div>
						<div class="td">
							<p>&nbsp;</p>
						</div>
					</div>
					<div>
						<div class="td" style="border: solid 1px;  background: red; page-break-inside: avoid;">
							<p style="font-size: 9.0pt; font-family: 'Arial','sans-serif'; color: white; text-align:left;"><strong>COMENTARIOS DEL CIUDADANO GOBERNADOR:</strong></p>
						</div>
					</div>
					<div>
						<div class="td" style="border: solid 1px;" class="mceNonEditable">
							<!--comments-->&nbsp;
						</div>
					</div>
				</div>
			</div>
			<br>
		    <div>
		      <table style="border-collapse: collapse; width: 100%;" border="0" class="">
		      <tbody>
		      <tr>
		      <td style="width: 33.3%; font-family: arial; text-align: center;">
		        <!--g-seal-->
		      </td>
		      <td style="width: 33.3%; font-family: arial; text-align:center;" class="mceNonEditable">
						<!--g-sign-->
						<p>G.O.E Nº 01 DECRETO Nº 01 DE FECHA 20/10/17</p>
		      </td>
		      <td style="width: 33.3%; font-family: arial; text-align: right;" class="mceNonEditable">
		        <!--qrcode-->
		      </td>
		      </tr>
		      </tbody>
		      </table>
			</div> 			
		</body>
	</html>

  	`;

	}

	fillPcIngreso(user?) {
		if (user == undefined) user = this.user;
		this.code = 4;
		return this.content = `
   <!DOCTYPE html>
	<html>
		<body>
		<span style="float:right; color: red;"><!--decision--></span>
	<table class="mceNonEditable" style="border-collapse: collapse; width: 100%;" border="0">
	<tbody>
	<tr>
	<td style="width: 10%; text-align: center;">
	<p>&nbsp;</p>
	<p><img style="margin-left: auto; margin-right: auto;" src="${this.escudo}" /></p>
	<pre style="text-align: center;">Rep&uacute;blica Bolivariana de Venezuela<br />Gobernaci&oacute;n del Estado Bolivariano de Gu&aacute;rico<br /><br /></pre>
	</td>
	<td style="width: 90%;">
	<p class="MsoBodyText3"><span lang="ES" style="font-size: 11.0pt; font-family: 'Arial Black','sans-serif'; mso-bidi-font-family: Arial;">PUNTO DE CUENTA DE INGRESO AL CIUDADANO GOBERNADOR DEL ESTADO &nbsp;BOLIVARIANO DE GU&Aacute;RICO</span></p>
	</td>
	</tr>
	</tbody>
	</table>
	<div>
      <table style="border-collapse: collapse; width: 100%; font-weight:bold;" border="1">
      <tr>
      <td style="width: 25%; font-family: arial;" class="mceNonEditable">
        <p style="font-size:12px;"><strong><!--correlativo--></strong></p>
      </td>
      <td style="width: 50%; font-family: arial; text-align:left;" class="mceNonEditable">
        <p style="font-size:12px;"><strong>CIUDADANO(A):</strong></p>
			<p style="font-size:12px;"><strong>${this.upperCase(user.firstname)} ${this.upperCase(user.lastname)}</strong></p>
			<p style="font-size:12px;"><strong>${this.upperCase(user.charge_h)} </strong></p>
      </td>
      <td style="width: 25%; font-family: arial; text-align:right" class="mceNonEditable">
        <p style="font-size:12px;"><strong><!--date--></strong></p>
      </td>
      </tr>
      </table>
	<table style="height: 150px; width: 150px; border-collapse: collapse; float: right; border-left: solid black 1px; border-right: solid black 1px; border-top: solid black 1px;">
	<tbody>
	<tr>
	<td style="width: 100%;" class="mceNonEditable"><!--fotoIngreso--></td>
	</tr>
	</tbody>
	</table>
	<div align="center">
	<table style="border-collapse: collapse; width: 100%;" border="1">
	<tbody>
	<tr style="mso-yfti-irow: 2; height: 6.75pt;">
	<td style="width: 531.7pt; border: solid windowtext 1.0pt;  background: red;  height: 6.75pt;" colspan="3" >
	<p  style= tab-stops: 35.4pt center 220.95pt right 441.9pt;"><strong><span lang="ES" style="font-size: 9.0pt; font-family: 'Arial','sans-serif'; color: white;">ASUNTO:</span></strong></p>
	</td>
	</tr>
	<tr style="mso-yfti-irow: 3; height: 1.4pt;">
	<td style="width: 531.7pt; border: solid windowtext 1.0pt;   height: 1.4pt;" colspan="3" valign="top">&nbsp;</td>
	</tr>
	<tr style="mso-yfti-irow: 2; height: 6.75pt;">
	<td style="width: 531.7pt; border: solid windowtext 1.0pt;  background: red;  height: 6.75pt;" colspan="3">
	<p  style= tab-stops: 35.4pt center 220.95pt right 441.9pt;"><strong><span lang="ES" style="font-size: 9.0pt; font-family: 'Arial','sans-serif'; color: white;">PROPUESTA:</span></strong></p>
	</td>
	</tr>
	<tr style="mso-yfti-irow: 3; height: 1.4pt;">
	<td style="width: 531.7pt; border: solid windowtext 1.0pt;   height: 1.4pt;" colspan="3" valign="top">&nbsp;</td>
	</tr>
	<tr style="mso-yfti-irow: 4; height: 6.75pt">
	<td style="width: 130.55pt; border: solid windowtext 1.0pt;   height: 6.75;">
	<p  style="margin-bottom: 1.0pt; text-align: center;"><strong><span lang="ES" style="font-size: 9.0pt; font-family: 'Arial','sans-serif';">NOMBRES Y APELLIDOS:</span></strong></p>
	</td>
	<td style="width: 401.15pt; border-top: none; border-left: none; border-bottom: solid windowtext 1.0pt; border-right: solid windowtext 1.0pt; mso-border-top-alt: solid windowtext .5pt; mso-border-left-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt;  height: 1.4pt; font-family: 'Arial','sans-serif';">&nbsp;</td>
	</tr>
	<tr style="mso-yfti-irow: 5; height: 6.75pt">
	<td style="width: 130.55pt; border: solid windowtext 1.0pt;   height: 1.4pt;">
	<p  style="margin-bottom: 1.0pt; text-align: center;"><strong><span lang="ES" style="font-size: 9.0pt; font-family: 'Arial','sans-serif';">CEDULA DE IDENTIDAD:</span></strong></p>
	</td>
	<td style="width: 401.15pt; border-top: none; border-left: none; border-bottom: solid windowtext 1.0pt; border-right: solid windowtext 1.0pt; mso-border-top-alt: solid windowtext .5pt; mso-border-left-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt;  height: 1.4pt; font-family: 'Arial','sans-serif';">&nbsp;</td>
	</tr>
	<tr style="mso-yfti-irow: 6; height: 1.4pt;">
	<td style="width: 130.55pt; border: solid windowtext 1.0pt;   height: 1.4pt;">
	<p  style="text-align: center;"><strong><span lang="ES" style="font-size: 9.0pt; font-family: 'Arial','sans-serif';">DIRECCI&Oacute;N DE HABITACI&Oacute;N:</span></strong></p>
	</td>
	<td style="width: 401.15pt; border-top: none; border-left: none; border-bottom: solid windowtext 1.0pt; border-right: solid windowtext 1.0pt; mso-border-top-alt: solid windowtext .5pt; mso-border-left-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt;  height: 1.4pt; font-family: 'Arial','sans-serif';">&nbsp;</td>
	</tr>
	<tr style="mso-yfti-irow: 7; height: 1.4pt;">
	<td style="width: 130.55pt; border: solid windowtext 1.0pt;   height: 1.4pt;">
	<p  style="margin-bottom: 1.0pt; text-align: center;"><strong><span lang="ES" style="font-size: 9.0pt; font-family: 'Arial','sans-serif';">PROFESI&Oacute;N:</span></strong></p>
	</td>
	<td style="width: 316.5pt; border-top: none; border-left: none; border-bottom: solid windowtext 1.0pt; border-right: solid windowtext 1.0pt; mso-border-top-alt: solid windowtext .5pt; mso-border-left-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt;  height: 1.4pt; font-family: 'Arial','sans-serif';">&nbsp;</td>
	</tr>
	<tr style="mso-yfti-irow: 8; height: 1.4pt;">
	<td style="width: 130.55pt; border: solid windowtext 1.0pt;   height: 1.4pt;">
	<p  style="margin-bottom: 1.0pt; text-align: center;"><strong><span lang="ES" style="font-size: 9.0pt; font-family: 'Arial','sans-serif';">TIEMPO DE SERVICIO: </span></strong></p>
	</td>
	<td style="width: 401.15pt; border-top: none; border-left: none; border-bottom: solid windowtext 1.0pt; border-right: solid windowtext 1.0pt; mso-border-top-alt: solid windowtext .5pt; mso-border-left-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt;  height: 1.4pt; font-family: 'Arial','sans-serif';">&nbsp;</td>
	</tr>
	<tr style="mso-yfti-irow: 9; height: 1.4pt;">
	<td style="width: 130.55pt; border: solid windowtext 1.0pt;   height: 1.4pt;">
	<p  style="margin-bottom: 1.0pt; text-align: center;"><strong><span lang="ES" style="font-size: 9.0pt; font-family: 'Arial','sans-serif';">&Uacute;LTIMOS CARGOS OCUPADOS:</span></strong></p>
	</td>
	<td style="width: 401.15pt; border-top: none; border-left: none; border-bottom: solid windowtext 1.0pt; border-right: solid windowtext 1.0pt; mso-border-top-alt: solid windowtext .5pt; mso-border-left-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt;  height: 1.4pt; font-family: 'Arial','sans-serif';">&nbsp;</td>
	</td>
	</tr>
	<tr style="mso-yfti-irow: 3; height: 1.4pt;">
	<td style="width: 531.7pt; border: solid windowtext 1.0pt;   height: 1.4pt; font-family: 'Arial','sans-serif';" colspan="3" valign="top">&nbsp;</td>
	</tr>
	<tr style="mso-yfti-irow: 2; height: 1.4pt;">
	<td style="width: 531.7pt; border: solid windowtext 1.0pt;  background: red;  height: 6.75pt;" colspan="3" >
	<p  style= tab-stops: 35.4pt center 220.95pt right 441.9pt;"><strong><span lang="ES" style="font-size: 9.0pt; font-family: 'Arial','sans-serif'; color: white;">COMENTARIOS DEL CIUDADANO GOBERNADOR:</span></strong></p>
	</td>
	</tr>
	<tr style="mso-yfti-irow: 3; height: 1.4pt;">
	<td style="width: 531.7pt; border: solid windowtext 1.0pt;   height: 1.4pt;" colspan="3" valign="top" class="mceNonEditable"><!--comments-->&nbsp;</td>
	</tr>
	</tbody>
	</table>
	</div>
	<br>
    <div>
      <table style="border-collapse: collapse; width: 100%;" border="0" class="">
      <tbody>
      <tr>
      <td style="width: 33.3%; font-family: arial; text-align: center;">
        <!--g-seal-->
      </td>
      <td style="width: 33.3%; font-family: arial; text-align:center;" class="mceNonEditable">
				<!--g-sign-->
      </td>
      <td style="width: 33.3%; font-family: arial; text-align: right;" class="mceNonEditable">
        <!--qrcode-->
      </td>
      </tr>
      </tbody>
      </table>
	</div> 
	</body>
	</html>
	`;
	}

	fillPcEgreso(user?) {
		if (user == undefined) user = this.user;
		this.code = 5;
		return this.content = `
   <!DOCTYPE html>
	<html>
		<body>
		<span style="float:right; color: red;"><!--decision--></span>
	<table class="mceNonEditable" style="border-collapse: collapse; width: 100%;" border="0">
	<tbody>
	<tr>
	<td style="width: 10%; text-align: center;">
	<p>&nbsp;</p>
	<p><img style="margin-left: auto; margin-right: auto;" src="${this.escudo}" /></p>
	<pre style="text-align: center;">Rep&uacute;blica Bolivariana de Venezuela<br />Gobernaci&oacute;n del Estado Bolivariano de Gu&aacute;rico<br /><br /></pre>
	</td>
	<td style="width: 90%;">
	<p class="MsoBodyText3"><span lang="ES" style="font-size: 11.0pt; font-family: 'Arial Black','sans-serif'; mso-bidi-font-family: Arial;">PUNTO DE CUENTA DE EGRESO AL CIUDADANO GOBERNADOR DEL ESTADO &nbsp;BOLIVARIANO DE GU&Aacute;RICO</span></p>
	</td>
	</tr>
	</tbody>
	</table>
	<div>
      <table style="border-collapse: collapse; width: 100%; font-weight:bold;" border="1">
      <tr>
      <td style="width: 25%; font-family: arial;" class="mceNonEditable">
        <p style="font-size:12px;"><strong><!--correlativo--></strong></p>
      </td>
      <td style="width: 50%; font-family: arial; text-align:left;" class="mceNonEditable">
        <p style="font-size:12px;"><strong>CIUDADANO(A):</strong></p>
			<p style="font-size:12px;"><strong>${this.upperCase(user.firstname)} ${this.upperCase(user.lastname)}</strong></p>
			<p style="font-size:12px;"><strong>${this.upperCase(user.charge_h)} </strong></p>
      </td>
      <td style="width: 25%; font-family: arial; text-align:right" class="mceNonEditable">
        <p style="font-size:12px;"><strong><!--date--></strong></p>
      </td>
      </tr>
      </table>
    </div>	  
	<table style="height: 150px; width: 150px; border-collapse: collapse; float: right; border-left: solid black 1px; border-right: solid black 1px; border-top: solid black 1px;">
	<tbody>
	<tr>
	<td style="width: 100%;" class="mceNonEditable"><!--fotoIngreso--></td>
	</tr>
	</tbody>
	</table>
	<div align="center">
	<table style="border-collapse: collapse; width: 100%;" border="1">
	<tbody>
	<tr style="mso-yfti-irow: 2; height: 6.75pt;">
	<td style="width: 531.7pt; border: solid windowtext 1.0pt;  background: red;  height: 6.75pt;" colspan="3" >
	<p  style= tab-stops: 35.4pt center 220.95pt right 441.9pt;"><strong><span lang="ES" style="font-size: 9.0pt; font-family: 'Arial','sans-serif'; color: white;">ASUNTO:</span></strong></p>
	</td>
	</tr>
	<tr style="mso-yfti-irow: 3; height: 1.4pt;">
	<td style="width: 531.7pt; border: solid windowtext 1.0pt;   height: 1.4pt;" colspan="3" valign="top">&nbsp;</td>
	</tr>
	<tr style="mso-yfti-irow: 2; height: 6.75pt;">
	<td style="width: 531.7pt; border: solid windowtext 1.0pt;  background: red;  height: 6.75pt;" colspan="3">
	<p  style= tab-stops: 35.4pt center 220.95pt right 441.9pt;"><strong><span lang="ES" style="font-size: 9.0pt; font-family: 'Arial','sans-serif'; color: white;">PROPUESTA:</span></strong></p>
	</td>
	</tr>
	<tr style="mso-yfti-irow: 3; height: 1.4pt;">
	<td style="width: 531.7pt; border: solid windowtext 1.0pt;   height: 1.4pt;" colspan="3" valign="top">&nbsp;</td>
	</tr>
	<tr style="mso-yfti-irow: 4; height: 6.75pt">
	<td style="width: 130.55pt; border: solid windowtext 1.0pt;   height: 6.75;">
	<p  style="margin-bottom: 1.0pt; text-align: center;"><strong><span lang="ES" style="font-size: 9.0pt; font-family: 'Arial','sans-serif';">NOMBRES Y APELLIDOS:</span></strong></p>
	</td>
	<td style="width: 401.15pt; border-top: none; border-left: none; border-bottom: solid windowtext 1.0pt; border-right: solid windowtext 1.0pt; mso-border-top-alt: solid windowtext .5pt; mso-border-left-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt;  height: 1.4pt; font-family: 'Arial','sans-serif';">&nbsp;</td>
	</tr>
	<tr style="mso-yfti-irow: 5; height: 6.75pt">
	<td style="width: 130.55pt; border: solid windowtext 1.0pt;   height: 1.4pt;">
	<p  style="margin-bottom: 1.0pt; text-align: center;"><strong><span lang="ES" style="font-size: 9.0pt; font-family: 'Arial','sans-serif';">CEDULA DE IDENTIDAD:</span></strong></p>
	</td>
	<td style="width: 401.15pt; border-top: none; border-left: none; border-bottom: solid windowtext 1.0pt; border-right: solid windowtext 1.0pt; mso-border-top-alt: solid windowtext .5pt; mso-border-left-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt;  height: 1.4pt; font-family: 'Arial','sans-serif';">&nbsp;</td>
	</tr>
	<tr style="mso-yfti-irow: 6; height: 1.4pt;">
	<td style="width: 130.55pt; border: solid windowtext 1.0pt;   height: 1.4pt;">
	<p  style="text-align: center;"><strong><span lang="ES" style="font-size: 9.0pt; font-family: 'Arial','sans-serif';">DIRECCI&Oacute;N DE HABITACI&Oacute;N:</span></strong></p>
	</td>
	<td style="width: 401.15pt; border-top: none; border-left: none; border-bottom: solid windowtext 1.0pt; border-right: solid windowtext 1.0pt; mso-border-top-alt: solid windowtext .5pt; mso-border-left-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt;  height: 1.4pt; font-family: 'Arial','sans-serif';">&nbsp;</td>
	</tr>
	<tr style="mso-yfti-irow: 7; height: 1.4pt;">
	<td style="width: 130.55pt; border: solid windowtext 1.0pt;   height: 1.4pt;">
	<p  style="margin-bottom: 1.0pt; text-align: center;"><strong><span lang="ES" style="font-size: 9.0pt; font-family: 'Arial','sans-serif';">PROFESI&Oacute;N:</span></strong></p>
	</td>
	<td style="width: 316.5pt; border-top: none; border-left: none; border-bottom: solid windowtext 1.0pt; border-right: solid windowtext 1.0pt; mso-border-top-alt: solid windowtext .5pt; mso-border-left-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt;  height: 1.4pt; font-family: 'Arial','sans-serif';">&nbsp;</td>
	</tr>
	<tr style="mso-yfti-irow: 8; height: 1.4pt;">
	<td style="width: 130.55pt; border: solid windowtext 1.0pt;   height: 1.4pt;">
	<p  style="margin-bottom: 1.0pt; text-align: center;"><strong><span lang="ES" style="font-size: 9.0pt; font-family: 'Arial','sans-serif';">TIEMPO DE SERVICIO: </span></strong></p>
	</td>
	<td style="width: 401.15pt; border-top: none; border-left: none; border-bottom: solid windowtext 1.0pt; border-right: solid windowtext 1.0pt; mso-border-top-alt: solid windowtext .5pt; mso-border-left-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt;  height: 1.4pt; font-family: 'Arial','sans-serif';">&nbsp;</td>
	</tr>
	<tr style="mso-yfti-irow: 9; height: 1.4pt;">
	<td style="width: 130.55pt; border: solid windowtext 1.0pt;   height: 1.4pt;">
	<p  style="margin-bottom: 1.0pt; text-align: center;"><strong><span lang="ES" style="font-size: 9.0pt; font-family: 'Arial','sans-serif';">&Uacute;LTIMOS CARGOS OCUPADOS:</span></strong></p>
	</td>
	<td style="width: 401.15pt; border-top: none; border-left: none; border-bottom: solid windowtext 1.0pt; border-right: solid windowtext 1.0pt; mso-border-top-alt: solid windowtext .5pt; mso-border-left-alt: solid windowtext .5pt; mso-border-alt: solid windowtext .5pt;  height: 1.4pt; font-family: 'Arial','sans-serif';">&nbsp;</td>
	</td>
	</tr>
	<tr style="mso-yfti-irow: 3; height: 1.4pt;">
	<td style="width: 531.7pt; border: solid windowtext 1.0pt;   height: 1.4pt; font-family: 'Arial','sans-serif';" colspan="3" valign="top">&nbsp;</td>
	</tr>
	<tr style="mso-yfti-irow: 2; height: 1.4pt;">
	<td style="width: 531.7pt; border: solid windowtext 1.0pt;  background: red;  height: 6.75pt;" colspan="3" >
	<p  style= tab-stops: 35.4pt center 220.95pt right 441.9pt;"><strong><span lang="ES" style="font-size: 9.0pt; font-family: 'Arial','sans-serif'; color: white;">COMENTARIOS DEL CIUDADANO GOBERNADOR:</span></strong></p>
	</td>
	</tr>
	<tr style="mso-yfti-irow: 3; height: 1.4pt;">
	<td style="width: 531.7pt; border: solid windowtext 1.0pt;   height: 1.4pt;" colspan="3" valign="top" class="mceNonEditable"><!--comments-->&nbsp;</td>
	</tr>
	</tbody>
	</table>
	</div>
	<br>
    <div>
      <table style="border-collapse: collapse; width: 100%;" border="0" class="">
      <tbody>
      <tr>
      <td style="width: 33.3%; font-family: arial; text-align: center;">
        <!--g-seal-->
      </td>
      <td style="width: 33.3%; font-family: arial; text-align:center;" class="mceNonEditable">
				<!--g-sign-->
				<p style="font-size: 9.5pt; font-family: 'Arial','sans-serif';"><strong>DELEGACIÓN DE FIRMA DECRETO Nº 306 SEGÚN G.O.E 210 DE FECHA 11/10/18</strong></p>
      </td>
      <td style="width: 33.3%; font-family: arial; text-align: right;" class="mceNonEditable">
        <!--qrcode-->
      </td>
      </tr>
      </tbody>
      </table>
	</div> 
	</body>
	</html>
	`;
	}

	upperCase(w) {
		return w.toUpperCase();
	}

	ngOnInit() {
	}
	content;
}