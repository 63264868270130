import { Component, OnInit } from '@angular/core';
import { Utils } from '../utils';
import { AbstractDocument } from '../abstract-doc';
@Component({
	selector: 'app-decreto',
	templateUrl: './decreto.component.html',
	styleUrls: ['./decreto.component.scss']
})
export class DecretoComponent extends AbstractDocument implements OnInit {

	constructor() {
		super();
	}

	fillResolucion(user?) {
		this.code = 9;
		this.acronym = '';
		let fecha = new Date();
		return this.content = `<!DOCTYPE html>
    <html>
    <head>
    </head>
    <body>
		<table class="mceNonEditable" style="border-collapse: collapse; width: 100%;" border="0">
			<tbody>
				<tr>
					<td style="width: 20%;">
						<img style="float: left;" src="${this.logo}" />
					</td>
					<td style="width: 60%; text-align: center; font-family: arial; font-size: 13px; font-weight: bold;">${this.mem}</td>
					<td style="width: 20%;">
						<img style="float: right;" src="${this.escudo}" />
					</td>
				</tr>
			</tbody>
		</table>
		<div>
			<table style="border-collapse: collapse; width: 100%;" border="0">
				<tbody>
					<tr>
						<td style="width: 100%;">
							<p class="MsoNormal" style="text-align: right;" align="right">
								<span lang="ES" style="font-family: 'Arial','sans-serif';">San Juan de los Morros,&nbsp; ${ fecha.getDate()} de ${Utils.months[fecha.getMonth()]} de ${fecha.getFullYear()}</span>
							</p>
							<p class="MsoNormal" style="text-align: right;" align="right">
								<span lang="ES" style="font-family: 'Arial','sans-serif';">${fecha.getFullYear() - 1810}&deg; Y ${fecha.getFullYear() - 1859}&deg;</span>
							</p>
							<p class="MsoNormal" style="text-align: center;" align="center">
								<strong>
									<span lang="ES" style="font-family: 'Arial','sans-serif';">&nbsp;</span>
								</strong>
							</p>
							<p class="MsoNormal" style="text-align: justify;">
								<strong>
									<span lang="ES" style="font-family: 'Arial','sans-serif';">RESOLUCI&Oacute;N N&ordm;&nbsp;
										<!--correlativo-->
									</span>
								</strong>
							</p>
							<p class="MsoNormal" style="text-align: justify;">&nbsp;</p>
							<p class="MsoNormal" style="text-align: justify;">&nbsp;</p>
							<p class="MsoNormal" style="text-align: justify;">&nbsp;</p>
							<p class="MsoNormal" style="text-align: justify;">&nbsp;</p>
							<p class="MsoNormal" style="text-align: justify;">&nbsp;</p>
							<p class="MsoNormal" style="text-align: justify;">&nbsp;</p>
							<p class="MsoNormal" style="text-align: justify;">&nbsp;</p>
							<p class="MsoNormal" style="text-align: justify;">&nbsp;</p>
							<p class="MsoNormal" style="text-align: center; text-indent: 35.4pt;" align="center">&nbsp;</p>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
		<div>
			<table style="border-collapse: collapse; width: 100%;" border="0">
				<tbody>
					<tr>
						<td style="width: 33.3%; font-family: arial;">
							<p style="font-size: 12px;">
								<!--seal-->
							</p>
						</td>
						<td class="mceNonEditable" style="width: 33.3%; font-family: arial; text-align: center;">
							<!--sign-->
						</td>
						<td class="mceNonEditable" style="width: 33.3%; font-family: arial;">
							<!--qrcode-->
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</body>
    </html>
  	`;
	}

	fillErratas(user?) {
		this.code = 8;
		this.acronym = 'ERRATAS';
		let fecha = new Date();
		return this.content = `
		<!DOCTYPE html>
		<html>
		<head>
		</head>
		<body>
			<table style="width: 100%; border-collapse: collapse;" border="0">
				<tbody>
					<tr>
						<td style="width: 45%;">
							<p style="text-align: center;">Rep&uacute;blica Bolivariana de Venezuela</p>
							<p style="text-align: center;">Gobernaci&oacute;n del Estado Bolivariano de Gu&aacute;rico</p>
							<p style="text-align: center;">
								<img style="display: block; margin-left: auto; margin-right: auto;" src="${this.escudo}" />
							</p>
							<p style="text-align: center;">Despacho del Gobernador</p>
						</td>
						<td style="width: 55%;">
							<p style="text-align: right;">
								<!--qrcode-->
							</p>
						</td>
					</tr>
				</tbody>
			</table>
			<table style="border-collapse: collapse; width: 100%;" border="0">
				<tbody>
					<tr>
						<td style="width: 100%;">
							<p class="MsoNormal" style="text-align: justify; line-height: 115%;">&nbsp;</p>
							<p class="MsoNormal" style="line-height: 115%; text-align: center;">
								<strong>
									<span style="font-size: 12.0pt; line-height: 115%; font-family: 'Times New Roman','serif'; mso-fareast-font-family: Calibri; mso-ansi-language:
					ES-VE; mso-fareast-language: EN-US; mso-bidi-language: AR-SA;">FE DE ERRATAS</span>
								</strong>
							</p>
							<p class="MsoNormal" style="line-height: 115%; text-align: center;">&nbsp;</p>
							<p class="MsoNormal" style="line-height: 115%; text-align: left;">&nbsp;</p>
							<p class="MsoNormal" style="line-height: 115%; text-align: left;">&nbsp;</p>
							<p class="MsoNormal" style="line-height: 115%; text-align: left;">&nbsp;</p>
							<p class="MsoNormal" style="line-height: 115%; text-align: left;">&nbsp;</p>
							<p class="MsoNormal" style="margin-bottom: .0001pt;">
								<span style="font-family: 'Times New Roman', serif;">La presente F&eacute; de Erratas deber&aacute; ser anexada a la Gaceta Oficial del Estado Bolivariano
									de Gu&aacute;rico Extraordinaria N&deg;179 de fecha: ${ fecha.getDate()} d&iacute;as del mes
									de ${Utils.months[fecha.getMonth()]} de ${fecha.getFullYear()} , para que forme parte integrante
									de la&nbsp; misma.</span>
							</p>
							<p class="MsoNormal" style="margin-bottom: .0001pt;">&nbsp;</p>
							<p class="MsoNormal" style="line-height: 115%; text-align: left;">
								<span style="font-size: 12.0pt; line-height: 115%; font-family: 'Times New Roman','serif'; mso-fareast-font-family: Calibri; mso-ansi-language: ES-VE; mso-fareast-language: EN-US; mso-bidi-language: AR-SA;">
									Dada, firmada y refrendada en el Despacho Ejecutivo del Palacio de Gobierno, en San Juan de los Morros, Estado Bolivariano
									de Gu&aacute;rico, a los ${Utils.days[fecha.getDate() - 1]} (${fecha.getDate()}) d&iacute;as
									del mes de ${Utils.months[fecha.getMonth()]} del a&ntilde;o ${fecha.getFullYear()}. A&ntilde;os
									${fecha.getFullYear() - 1810}&ordm; de la Independencia&nbsp; y ${fecha.getFullYear() - 1859}&ordm;
									de la Federaci&oacute;n.</span>
							</p>
							<p class="MsoNormal" style="text-align: justify; line-height: 115%;">&nbsp;</p>
						</td>
					</tr>
					<div>
						<table style="border-collapse: collapse; width: 100%;" border="0">
							<tbody>
								<tr>
									<td style="width: 100%; font-family: arial;">
										<div align="center">
											<p class="MsoNormal" style="display:table-cell;" align="center">
												<!--seal-->
											</p>
											<p class="MsoNormal" style="display:table-cell;" align="center">
												<!--sign-->
											</p>
										</div>
										<p class="MsoNormal" style="margin-bottom: .0001pt; text-align: center; line-height: normal;" align="center">
											<strong>
												<span style="font-family: 'Times New Roman','serif';">JOSE DIONISIO GONCALVEZ MENDOZA </span>
											</strong>
										</p>
										<p class="MsoNormal" style="margin-bottom: .0001pt; text-align: center; line-height: normal;" align="center">
											<strong>
												<span style="font-family: 'Times New Roman','serif';">SECRETARIO GENERAL DE GOBIERNO (E)</span>
											</strong>
										</p>
										<p class="MsoNormal" style="text-align: center;" align="center">
											<span style="font-family: 'Times New Roman','serif';">
												<strong>DEL ESTADO BOLIVARIANO DE GU&Aacute;RICO</strong>
											</span>
										</p>
										<span style="font-size: 9.0pt; mso-bidi-font-size: 12.0pt; line-height: 115%; font-family: 'Times New Roman','serif'; mso-fareast-font-family: Calibri; mso-ansi-language: ES-VE; mso-fareast-language: EN-US; mso-bidi-language: AR-SA;">//</span>
									</td>
							</tbody>
						</table>
					</div>
				</tbody>
			</table>
		</body>
		</html>
    `;


	}



	fillDesignacion(user?) {
		this.code = 7;
		this.acronym = 'DESIGANCION';
		let fecha = new Date();
		return this.content = `
		<!DOCTYPE html>
		<html>
		<head>
		</head>
		<body>
		<table style="width: 100%; border-collapse: collapse;" border="0">
			<tbody>
				<tr>
					<td style="width: 45%;">
						<p style="text-align: center;">Rep&uacute;blica Bolivariana de Venezuela</p>
						<p style="text-align: center;">Gobernaci&oacute;n del Estado Bolivariano de Gu&aacute;rico</p>
						<p style="text-align: center;">
							<img style="display: block; margin-left: auto; margin-right: auto;" src="${this.escudo}" />
						</p>
						<p style="text-align: center;">Despacho del Gobernador</p>
					</td>
					<td style="width: 55%;">
						<p style="text-align: right;">
							<!--qrcode-->
						</p>
					</td>
				</tr>
			</tbody>
		</table>
		<table style="border-collapse: collapse; width: 100%;" border="0">
			<tbody>
				<tr>
					<td style="width: 100%;">
						<p>&nbsp;</p>
						<p class="MsoNormal" style="text-align: center;" align="center">
							<strong>
								<span lang="ES" style="font-family: 'Arial','sans-serif';">DECRETO N&ordm;</span>
							</strong>
						</p>
						<p class="MsoNormal">
							<span lang="ES" style="font-family: 'Arial','sans-serif';">&nbsp;</span>
						</p>
						<p class="MsoNormal" style="text-align: center; tab-stops: 85.0pt;" align="center">
							<strong>
								<span lang="ES" style="font-family: 'Arial','sans-serif';">JOSE MANUEL VASQUEZ ARANGUREN</span>
							</strong>
						</p>
						<p class="MsoNormal" style="text-align: center; line-height: 115%; tab-stops: 85.0pt;" align="center">
							<strong>
								<span lang="ES" style="font-family: 'Arial','sans-serif';">GOBERNADOR&nbsp; DEL ESTADO BOLIVARIANO DE GU&Aacute;RICO</span>
							</strong>
						</p>
						<p class="MsoNormal" style="text-align: justify; line-height: 115%; tab-stops: 85.0pt;">
							<strong>
								<span lang="ES" style="font-family: 'Arial','sans-serif';">&nbsp;</span>
							</strong>
						</p>
						<p class="MsoNormal" style="text-align: justify; line-height: 115%; tab-stops: 85.0pt;">
							<span lang="ES" style="font-family: 'Arial','sans-serif';">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; En uso de las atribuciones legales que me confiere el art&iacute;culo
								160 de la Constituci&oacute;n de la Rep&uacute;blica Bolivariana de Venezuela, en armon&iacute;a
								con los art&iacute;culos 51 y 55, numeral 5 de la Constituci&oacute;n del Estado Bolivariano
								de Gu&aacute;rico.</span>
						</p>
						<p class="MsoNormal" style="text-align: center; line-height: 115%; tab-stops: 85.0pt;" align="center">
							<span lang="ES" style="font-family: 'Arial','sans-serif';">&nbsp;</span>
						</p>
						<p class="MsoNormal" style="text-align: center; line-height: 115%; tab-stops: 85.0pt;" align="center">
							<strong>
								<span lang="ES" style="font-family: 'Arial','sans-serif';">DECRETO</span>
							</strong>
						</p>
						<p class="MsoNormal" style="text-align: justify; line-height: 115%; mso-pagination: none;">
							<strong>
								<span lang="ES" style="font-family: 'Arial','sans-serif';">&nbsp;</span>
							</strong>
						</p>
						<p>
							<strong>
								<span lang="ES" style="font-size: 12.0pt; font-family: 'Arial','sans-serif'; mso-fareast-font-family: 'Times New Roman'; mso-ansi-language: ES; mso-fareast-language: ES; mso-bidi-language: AR-SA;">ARTICULO PRIMERO:
								</span>
							</strong>
							<span lang="ES" style="font-size: 12.0pt; font-family: 'Arial','sans-serif'; mso-fareast-font-family: 'Times New Roman'; mso-ansi-language: ES; mso-fareast-language: ES; mso-bidi-language: AR-SA;">Designo al ciudadano(a):
								<strong> _______________________________, </strong>venezolano, mayor de edad, titular de la c&eacute;dula
								de identidad
								<strong> N&deg; V-&nbsp;&nbsp;&nbsp; _________, </strong>como
								<strong>SECRETARIO _______________________DE LA GOBERNACION DEL ESTADO BOLIVARIANO DE GU&Aacute;RICO,
								</strong>en sustituci&oacute;n del ciudadano(a):
								<strong> ____________________, </strong>venezolano, mayor de edad, titular de la c&eacute;dula de identidad
								<strong>
									N&deg; V-&nbsp; _______________, </strong>a partir de la presente fecha.</span>
								</p>
								<p>&nbsp;</p>
								<p>&nbsp;</p>
								<p>&nbsp;</p>
								<p>&nbsp;</p>
								<p class="MsoNormal" style="text-align: center; line-height: 115%;">
									<strong>
										<span lang="ES-TRAD" style="font-family: 'Century Gothic','sans-serif'; mso-bidi-font-family: Arial;">Comun&iacute;quese y Publ&iacute;quese, en Gaceta Oficial&nbsp; del Estado Bolivariano de Gu&aacute;rico.</span>
									</strong>
								</p>
								<p class="MsoNormal" style="text-align: justify; line-height: 115%; tab-stops: 85.0pt;">
									<strong>
										<span lang="ES-TRAD" style="font-family: 'Century Gothic','sans-serif'; mso-bidi-font-family: Arial;">&nbsp;</span>
									</strong>
								</p>
								<p class="MsoNormal" style="text-align: justify; line-height: 115%;">
									<span lang="ES-TRAD" style="font-family: 'Century Gothic','sans-serif'; mso-bidi-font-family: Arial;">&nbsp;&nbsp;&nbsp;&nbsp; Dado, firmado, refrendado y sellado, sin&nbsp; enmienda ni tachadura en
										la sede del Palacio de Gobierno. En la ciudad de San Juan de los Morros, Estado Bolivariano de
										Gu&aacute;rico, a los ${fecha.getDate()} d&iacute;as del mes de ${Utils.months[fecha.getMonth()]}
										del a&ntilde;o ${fecha.getFullYear()}. A&ntilde;os ${fecha.getFullYear() - 1810}&ordm; de la
										Independencia&nbsp; y ${fecha.getFullYear() - 1859}&ordm; de la Federaci&oacute;n.</span>
								</p>
								<p class="MsoNormal" style="text-align: justify; line-height: 115%;">&nbsp;</p>
							</td>
						</tr>
					</tbody>
				</table>
		<table style="border-collapse: collapse; width: 100%;" border="0" class="mceNonEditable">
			<tbody>
				<tr>
					<td style="width: 50%; font-family: arial; font-size: 9.0pt;">
						<p class="MsoNormal">
							<span lang="ES-TRAD" style="font-family: 'Arial','sans-serif';">&nbsp;</span>
						</p>
						<p class="MsoNormal">
							<span lang="ES-TRAD" style="font-family: 'Arial','sans-serif';">&nbsp;</span>
						</p>
						<div align="center">
							<p class="MsoNormal" style="display:table-cell;" align="center">
								<!--g-seal-->
							</p>
							<p class="MsoNormal" style="display:table-cell;" align="center">
								<!--g-sign-->
							</p>
						</div>
						<p class="MsoNormal" style="text-align: center; tab-stops: 85.0pt;" align="center">
							<strong>
								<span lang="ES-TRAD" style="font-family: 'Arial','sans-serif';">JOSE MANUEL VASQUEZ ARANGUREN</span>
							</strong>
						</p>
						<p class="MsoNormal" style="text-align: center; tab-stops: 85.0pt;" align="center">
							<strong>
								<span lang="ES-TRAD" style="font-family: 'Arial','sans-serif';">GOBERNADOR&nbsp; DEL ESTADO BOLIVARIANO DE GU&Aacute;RICO</span>
							</strong>
						</p>
					</td>
					<td style="width: 50%; font-family: arial; text-align:center; font-size: 9.0pt;">
						<p class="MsoNormal">
 							<span lang="ES-TRAD" style="font-family: 'Arial','sans-serif';">Refrendado:</span>
						</p>
						<p class="MsoNormal">
							<span lang="ES-TRAD" style="font-family: 'Arial','sans-serif';">&nbsp;</span>
						</p>
						<div align="center">
							<p class="MsoNormal" style="display:table-cell;" align="center">
								<!--seal-->
							</p>
							<p class="MsoNormal" style="display:table-cell;" align="center">
								<!--sign-->
							</p>
						</div>
						<p class="MsoNormal" style="margin-bottom: .0001pt; text-align: center; line-height: normal;" align="center">
							<strong>
								<span style="font- font-family: 'Times New Roman','serif';">JOSE DIONISIO GONCALVEZ MENDOZA </span>
							</strong>
						</p>
						<p class="MsoNormal" style="margin-bottom: .0001pt; text-align: center; line-height: normal;" align="center">
							<strong>
								<span style="font-family: 'Times New Roman','serif';">SECRETARIO GENERAL DE GOBIERNO (E)</span>
							</strong>
						</p>
						<p class="MsoNormal" style="text-align: center;" align="center">
							<span style="font-family: 'Times New Roman','serif';">
								<strong>DEL ESTADO BOLIVARIANO DE GU&Aacute;RICO</strong>
							</span>
						</p>
						<span style="font-size: 9.0pt; mso-bidi-font-size: 12.0pt; line-height: 115%; font-family: 'Times New Roman','serif'; mso-fareast-font-family: Calibri; mso-ansi-language: ES-VE; mso-fareast-language: EN-US; mso-bidi-language: AR-SA;">//</span>
					</td>
				</tr>
				</tbody>
			</table>		</body>
		</html>
    `;

	}

	fillDecreto(user?) {
		this.code = 6;
		this.acronym = 'DECRETO';
		let fecha = new Date();
		return this.content = `
	    <!DOCTYPE html>
	    <html>
	    <head>
	    </head>
		<body>
			<table style="width: 100%; border-collapse: collapse;" border="0">
				<tbody>
					<tr>
						<td style="width: 45%;">
							<p style="text-align: center;">Rep&uacute;blica Bolivariana de Venezuela</p>
							<p style="text-align: center;">Gobernaci&oacute;n del Estado Bolivariano de Gu&aacute;rico</p>
							<p style="text-align: center;"><img style="display: block; margin-left: auto; margin-right: auto;" src="${this.escudo}" /></p>
							<p style="text-align: center;">Despacho del Gobernador</p>
						</td>
						<td style="width: 55%;">
							<p style="text-align: right;"><!--qrcode--></p>
						</td>
					</tr>
				</tbody>
			</table>
			<table style="border-collapse: collapse; width: 100%;" border="0">
				<tbody>
					<tr>
						<td style="width: 100%;">
							<p>&nbsp;</p>
							<p>&nbsp;</p>
							<p>&nbsp;</p>
							<p>&nbsp;</p>
							<p>&nbsp;</p>
							<p>&nbsp;</p>
							<p>&nbsp;</p>
							<p>&nbsp;</p>
							<p>&nbsp;</p>
							<p>&nbsp;</p>
							<p class="MsoNormal" style="text-align: center; line-height: 115%;"><strong><span lang="ES-TRAD" style="font-family: 'Century Gothic','sans-serif'; mso-bidi-font-family: Arial;">Comun&iacute;quese y Publ&iacute;quese, en Gaceta Oficial&nbsp;
								del Estado Bolivariano de Gu&aacute;rico.</span></strong></p>
							<p class="MsoNormal" style="text-align: justify; line-height: 115%; tab-stops: 85.0pt;"><strong><span lang="ES-TRAD" style="font-family: 'Century Gothic','sans-serif'; mso-bidi-font-family: Arial;">&nbsp;</span></strong></p>
							<p class="MsoNormal" style="text-align: justify; line-height: 115%;"><span lang="ES-TRAD" style="font-family: 'Century Gothic','sans-serif'; mso-bidi-font-family: Arial;">&nbsp;&nbsp;&nbsp;&nbsp; Dado, firmado, refrendado y sellado, sin&nbsp; enmienda
								ni tachadura en la sede del Palacio de Gobierno. En la ciudad de San Juan de los Morros, Estado Bolivariano de Gu&aacute;rico,
								a los ${fecha.getDate()} d&iacute;as del mes de ${Utils.months[fecha.getMonth()]} del a&ntilde;o ${fecha.getFullYear()}. A&ntilde;os ${fecha.getFullYear() - 1810}&ordm; de la Independencia&nbsp; y ${fecha.getFullYear() - 1859}&ordm; de la Federaci&oacute;n.</span></p>
							<p class="MsoNormal" style="text-align: justify; line-height: 115%;">&nbsp;</p>
						</td>
					</tr>
				</tbody>
			</table>

			<table style="border-collapse: collapse; width: 100%;" border="0" class="mceNonEditable">
				<tbody>
				<tr>
				<td style="width: 50%; font-family: arial; font-size: 9.0pt;">
					<p class="MsoNormal">
						<span lang="ES-TRAD" style="font-family: 'Arial','sans-serif';">&nbsp;</span>
					</p>
					<p class="MsoNormal">
						<span lang="ES-TRAD" style="font-family: 'Arial','sans-serif';">&nbsp;</span>
					</p>
					<div align="center">
						<p class="MsoNormal" style="display:table-cell;" align="center">
							<!--g-seal-->
						</p>
						<p class="MsoNormal" style="display:table-cell;" align="center">
							<!--g-sign-->
						</p>
					</div>
					<p class="MsoNormal" style="text-align: center; tab-stops: 85.0pt;" align="center">
						<strong>
							<span lang="ES-TRAD" style="font-family: 'Arial','sans-serif';">JOSE MANUEL VASQUEZ ARANGUREN</span>
						</strong>
					</p>
					<p class="MsoNormal" style="text-align: center; tab-stops: 85.0pt;" align="center">
						<strong>
							<span lang="ES-TRAD" style="font-family: 'Arial','sans-serif';">GOBERNADOR&nbsp; DEL ESTADO BOLIVARIANO DE GU&Aacute;RICO</span>
						</strong>
					</p>
				</td>
				<td style="width: 50%; font-family: arial; text-align:center; font-size: 9.0pt;">
						<p class="MsoNormal">
 							<span lang="ES-TRAD" style="font-family: 'Arial','sans-serif';">Refrendado:</span>
						</p>
						<p class="MsoNormal">
							<span lang="ES-TRAD" style="font-family: 'Arial','sans-serif';">&nbsp;</span>
						</p>
						<div align="center">
							<p class="MsoNormal" style="display:table-cell;" align="center">
								<!--seal-->
							</p>
							<p class="MsoNormal" style="display:table-cell;" align="center">
								<!--sign-->
							</p>
						</div>
						<p class="MsoNormal" style="margin-bottom: .0001pt; text-align: center; line-height: normal;" align="center">
							<strong>
								<span style="font- font-family: 'Times New Roman','serif';">JOSE DIONISIO GONCALVEZ MENDOZA </span>
							</strong>
						</p>
						<p class="MsoNormal" style="margin-bottom: .0001pt; text-align: center; line-height: normal;" align="center">
							<strong>
								<span style="font-family: 'Times New Roman','serif';">SECRETARIO GENERAL DE GOBIERNO (E)</span>
							</strong>
						</p>
						<p class="MsoNormal" style="text-align: center;" align="center">
							<span style="font-family: 'Times New Roman','serif';">
								<strong>DEL ESTADO BOLIVARIANO DE GU&Aacute;RICO</strong>
							</span>
						</p>
						<span style="font-size: 9.0pt; mso-bidi-font-size: 12.0pt; line-height: 115%; font-family: 'Times New Roman','serif'; mso-fareast-font-family: Calibri; mso-ansi-language: ES-VE; mso-fareast-language: EN-US; mso-bidi-language: AR-SA;">//</span>
				</td>
				</tr>
				</tbody>
			</table>
		</body>
	    </html>
    `;

	}

	fillDecretoOficio(user?) {
		this.code = 11;
		this.acronym = 'DECRETO OFICIO';
		let fecha = new Date();
		return this.content = `
	    <!DOCTYPE html>
	    <html>
	    <head>
	    </head>
	    <body>
		<table style="width: 100%; border-collapse: collapse;" border="0">
			<tbody>
				<tr>
					<td style="width: 45%;">
						<p style="text-align: center;">Rep&uacute;blica Bolivariana de Venezuela</p>
						<p style="text-align: center;">Gobernaci&oacute;n del Estado Bolivariano de Gu&aacute;rico</p>
						<p style="text-align: center;">
							<img style="display: block; margin-left: auto; margin-right: auto;" src="${this.escudo}" />
						</p>
						<p style="text-align: center;">Despacho del Gobernador</p>
					</td>
					<td style="width: 55%;">
						<p style="text-align: right;">
							<!--qrcode-->
						</p>
					</td>
				</tr>
			</tbody>
		</table>
		<table style="border-collapse: collapse; width: 100%;" border="0">
			<tbody>
				<tr>
					<td style="width: 100%;">
						<p>&nbsp;</p>
						<p>&nbsp;</p>
						<p>&nbsp;</p>
						<p>&nbsp;</p>
						<p>&nbsp;</p>
						<p>&nbsp;</p>
						<p>&nbsp;</p>
						<p>&nbsp;</p>
						<p>&nbsp;</p>
						<p>&nbsp;</p>
						<p class="MsoNormal" style="text-align: center; line-height: 115%;">
							<strong>
								<span lang="ES-TRAD" style="font-family: 'Century Gothic','sans-serif'; mso-bidi-font-family: Arial;">Comun&iacute;quese y Publ&iacute;quese, en Gaceta Oficial&nbsp; del Estado Bolivariano de Gu&aacute;rico.</span>
							</strong>
						</p>
						<p class="MsoNormal" style="text-align: justify; line-height: 115%; tab-stops: 85.0pt;">
							<strong>
								<span lang="ES-TRAD" style="font-family: 'Century Gothic','sans-serif'; mso-bidi-font-family: Arial;">&nbsp;</span>
							</strong>
						</p>
						<p class="MsoNormal" style="text-align: justify; line-height: 115%;">
							<span lang="ES-TRAD" style="font-family: 'Century Gothic','sans-serif'; mso-bidi-font-family: Arial;">&nbsp;&nbsp;&nbsp;&nbsp; Dado, firmado, refrendado y sellado, sin&nbsp; enmienda ni tachadura en
								la sede del Palacio de Gobierno. En la ciudad de San Juan de los Morros, Estado Bolivariano de
								Gu&aacute;rico, a los ${fecha.getDate()} d&iacute;as del mes de ${Utils.months[fecha.getMonth()]}
								del a&ntilde;o ${fecha.getFullYear()}. A&ntilde;os ${fecha.getFullYear() - 1810}&ordm; de la
								Independencia&nbsp; y ${fecha.getFullYear() - 1859}&ordm; de la Federaci&oacute;n.</span>
						</p>
						<p class="MsoNormal" style="text-align: justify; line-height: 115%;">&nbsp;</p>
					</td>
				</tr>
				<tr>
					<td style="width: 100%;" class="mceNonEditable">
						<div align="center">
							<p class="MsoNormal" style="display:table-cell;" align="center">
								<!--g-seal-->
							</p>
							<p class="MsoNormal" style="display:table-cell;" align="center">
								<!--g-sign-->
							</p>
						</div>
						<p class="MsoNormal" style="text-align: center; tab-stops: 85.0pt;" align="center">
							<strong>
								<span lang="ES-TRAD" style="font-family: 'Arial','sans-serif';">JOSE MANUEL VASQUEZ ARANGUREN</span>
							</strong>
						</p>
						<p class="MsoNormal" style="text-align: center; tab-stops: 85.0pt;" align="center">
							<strong>
								<span lang="ES-TRAD" style="font-family: 'Arial','sans-serif';">GOBERNADOR&nbsp; DEL ESTADO BOLIVARIANO DE GU&Aacute;RICO</span>
							</strong>
						</p>
						<p class="MsoNormal">
							<span lang="ES-TRAD" style="font-family: 'Arial','sans-serif';">&nbsp;</span>
						</p>
						<p class="MsoNormal">
							<span lang="ES-TRAD" style="font-family: 'Arial','sans-serif';">Refrendado:</span>
						</p>
						<p class="MsoNormal">
							<span lang="ES-TRAD" style="font-family: 'Arial','sans-serif';">&nbsp;</span>
						</p>
						<p class="MsoNormal">
							<span lang="ES-TRAD" style="font-family: 'Arial','sans-serif';">&nbsp;</span>
						</p>
						<p class="MsoNormal">
							<span lang="ES-TRAD" style="font-family: 'Arial','sans-serif';">&nbsp;</span>
						</p>
						<div align="center">
							<p class="MsoNormal" style="display:table-cell;" align="center">
								<!--sg-seal-->
							</p>
							<p class="MsoNormal" style="display:table-cell;" align="center">
								<!--sg-sign-->
							</p>
						</div>
						<p class="MsoNormal" style="text-align: center;" align="center">
							<strong>
								<span lang="ES-TRAD">JOSE DIONISIO GONCALVEZ MENDOZA</span>
							</strong>
						</p>
						<p class="MsoNormal" style="text-align: center;" align="center">
							<strong>
								<span lang="ES-TRAD">SECRETARIO GENERAL DE GOBIERNO (E)</span>
							</strong>
						</p>
						<p class="MsoNormal" style="text-align: center;" align="center">
							<strong>
								<span lang="ES-TRAD" style="font-family: 'Arial','sans-serif';">&nbsp;</span>
							</strong>
						</p>
						<p class="MsoNormal">
							<span lang="ES-TRAD" style="font-family: 'Arial','sans-serif';">&nbsp;</span>
						</p>
						<p class="MsoNormal">
							<span lang="ES-TRAD" style="font-family: 'Arial','sans-serif';">&nbsp;</span>
						</p>
						<p class="MsoNormal">
							<span lang="ES-TRAD" style="font-family: 'Arial','sans-serif';">&nbsp;</span>
						</p>
					</td>
				</tr>
				<div>
					<table style="border-collapse: collapse; width: 100%;" border="0">
						<tbody>
							<tr>
								<td style="width: 100%; font-family: arial;">
									<div align="center">
										<p class="MsoNormal" style="display:table-cell;" align="center">
											<!--seal-->
										</p>
										<p class="MsoNormal" style="display:table-cell;" align="center">
											<!--sign-->
										</p>
									</div>
									<p class="MsoNormal" style="text-align: center;" align="center">
										<strong>
											<span lang="ES-TRAD">ALVIA DI NINO</span>
										</strong>
									</p>
									<p class="MsoNormal" style="text-align: center;" align="center">
										<strong>
											<span lang="ES-TRAD">SECRETARIA DE PLANFICACIÓN Y PRESUPUESTO (E)</span>
										</strong>
									</p>
									<p class="MsoNormal" style="text-align: justify;">
										<span lang="ES-TRAD" style="font-size: 8.0pt; mso-bidi-font-size: 12.0pt; font-family: 'Arial','sans-serif';">
										/// &nbsp;</span>
									</p>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</tbody>
		</table>
		</body>
		</html>

     `;
	}

   fillConstanciaUso(user?, cod=13, title='CONSTANCIA DE USO CONFORME'){
   this.code = cod;
   this.acronym = 'CONSTANCIA DE USO';
   let fecha = new Date();
   const titlex = title;
   return this.content = `
		<!DOCTYPE html>
		<html>
		<head>
		</head>
			<body>
				<table class="MsoNormalTable" style="width: 100%; border-collapse: collapse; border: none; mso-border-alt: solid windowtext 0pt; mso-padding-alt: 0cm 3.5pt 0cm 3.5pt; mso-border-insideh: 0pt solid windowtext; mso-border-insidev: 0pt solid windowtext;" border="0" width="100%" cellspacing="0" cellpadding="0">
					<tbody class="mceNonEditable" style="font-size:13px;">
						<tr style="mso-yfti-irow: 0; mso-yfti-firstrow: yes; height: 56.25pt;">
						<td style="width: 272.25pt; border: solid windowtext 0pt; mso-border-alt: solid windowtext 0pt; padding: 0cm 3.5pt 0cm 3.5pt; height: 56.25pt;" rowspan="2" valign="top" width="363">
						<p class="MsoNormal" style="text-align: center;">Rep&uacute;blica Bolivariana de Venezuela</p>
						<p class="MsoNormal" style="text-align: center;"><img style="display: block; margin-left: auto; margin-right: auto;" src="${this.escudo}" /></p>
						<p class="MsoNormal" style="text-align: center;">Consultor&iacute;a Jur&iacute;dica</p>
						<p class="MsoNormal" style="margin-bottom: .0001pt; line-height: normal;">&nbsp;</p>
						</td>
						<td style="width: 273.55pt; border: solid windowtext 0pt; border-left: none; mso-border-left-alt: solid windowtext 0pt; mso-border-alt: solid windowtext 0pt; padding: 0cm 3.5pt 0cm 3.5pt; height: 56.25pt;" valign="top" width="365">
						<p class="MsoNormal" style="text-align: center;" align="right">Gobernaci&oacute;n del Estado Bolivariano de Gu&aacute;rico</p>
						<p class="MsoNormal" style="text-align: center;"><img style="display: block; margin-left: auto; margin-right: auto;" src="${this.logo}" /></p>
						</td>
						</tr>
						<tr style="mso-yfti-irow: 1; mso-yfti-lastrow: yes; height: 12.35pt;">
						<td style="width: 273.55pt; border-top: none; border-left: none; border-bottom: solid windowtext 0pt; border-right: solid windowtext 0pt; mso-border-top-alt: solid windowtext 0pt; mso-border-left-alt: solid windowtext 0pt; mso-border-alt: solid windowtext 0pt; padding: 0cm 3.5pt 0cm 3.5pt; height: 12.35pt;" valign="top" width="365">
						<p class="MsoNormal" style="margin-bottom: .0001pt; text-align: right; line-height: normal;" align="right"><strong><!--correlativo--></strong></p>
						</td>
						</tr>
					</tbody>
				</table>
					<p style="text-align:center; font-family:arial; font-weight:bold;">${titlex} N° <!--correlativo--></p>
					<p>&nbsp;</p>
					<p>&nbsp;</p>
					<p>&nbsp;</p>
					<p>&nbsp;</p>
					<p>&nbsp;</p>
					<p>&nbsp;</p>
					<p>&nbsp;</p>
					<p>&nbsp;</p>
					<p>&nbsp;</p>
					<div>
				<table style="border-collapse: collapse; width: 100%; border="0" >
				<tbody>
				<tr>
				<td style="width: 33.3%; font-family: arial;">
					<!--seal-->
				</td>
				<td style="width: 33.3%; font-family: arial; text-align:center;" class="mceNonEditable">
					<!--sign-->
					<!--gaceta-->
				</td>
				<td align="right" style="width: 33.3%; font-family: arial;" class="mceNonEditable">
					<!--qrcode-->
				</td>
				</tr>
				</tbody>
				</table>
			</body>
    	</html>
   `
   }

   fillDocumentoConsultoria(user?){
   this.code = 16;
   this.acronym = 'DOCUMENTO CONSULTORIA';
   let fecha = new Date();
   return this.content = `
	    <!DOCTYPE html>
	    <html>
	    <head>
	    </head>
	    <body>
		<table style="width: 100%; border-collapse: collapse;" border="0">
			<tbody>
				<tr>
					<td style="width: 45%;">
						<p style="text-align: center;">Rep&uacute;blica Bolivariana de Venezuela</p>
						<p style="text-align: center;">Gobernaci&oacute;n del Estado Bolivariano de Gu&aacute;rico</p>
						<p style="text-align: center;">
							<img style="display: block; margin-left: auto; margin-right: auto;" src="${this.escudo}" />
						</p>
						<p style="text-align: center;">Despacho del Gobernador</p>
					</td>
					<td style="width: 55%;">
						<p style="text-align: right;">
							<!--qrcode-->
						</p>
					</td>
				</tr>
			</tbody>
		</table>
		<table style="border-collapse: collapse; width: 100%;" border="0">
			<tbody>
				<tr>
					<td style="width: 100%;">
						<p>&nbsp;</p>
						<p>&nbsp;</p>
						<p>&nbsp;</p>
						<p>&nbsp;</p>
						<p>&nbsp;</p>
						<p>&nbsp;</p>
						<p>&nbsp;</p>
						<p>&nbsp;</p>
						<p>&nbsp;</p>
						<p>&nbsp;</p>
						<p class="MsoNormal" style="text-align: center; line-height: 115%;">
							<strong>
								<span lang="ES-TRAD" style="font-family: 'Century Gothic','sans-serif'; mso-bidi-font-family: Arial;">Comun&iacute;quese y Publ&iacute;quese, en Gaceta Oficial&nbsp; del Estado Bolivariano de Gu&aacute;rico.</span>
							</strong>
						</p>
						<p class="MsoNormal" style="text-align: justify; line-height: 115%; tab-stops: 85.0pt;">
							<strong>
								<span lang="ES-TRAD" style="font-family: 'Century Gothic','sans-serif'; mso-bidi-font-family: Arial;">&nbsp;</span>
							</strong>
						</p>
						<p class="MsoNormal" style="text-align: justify; line-height: 115%;">
							<span lang="ES-TRAD" style="font-family: 'Century Gothic','sans-serif'; mso-bidi-font-family: Arial;">&nbsp;&nbsp;&nbsp;&nbsp; Dado, firmado y sellado, sin&nbsp; enmienda ni tachadura en
								la sede del Palacio de Gobierno. En la ciudad de San Juan de los Morros, Estado Bolivariano de
								Gu&aacute;rico, a los ${fecha.getDate()} d&iacute;as del mes de ${Utils.months[fecha.getMonth()]}
								del a&ntilde;o ${fecha.getFullYear()}. A&ntilde;os ${fecha.getFullYear() - 1810}&ordm; de la
								Independencia&nbsp; y ${fecha.getFullYear() - 1859}&ordm; de la Federaci&oacute;n.</span>
						</p>
						<p class="MsoNormal" style="text-align: justify; line-height: 115%;">&nbsp;</p>
					</td>
				</tr>
				<tr>
					<td style="width: 100%;" class="mceNonEditable">
						<div align="center">
							<p class="MsoNormal" style="display:table-cell;" align="center">
								<!--g-seal-->
							</p>
							<p class="MsoNormal" style="display:table-cell;" align="center">
								<!--g-sign-->
							</p>
						</div>
						<p class="MsoNormal" style="text-align: center; tab-stops: 85.0pt;" align="center">
							<strong>
								<span lang="ES-TRAD" style="font-family: 'Arial','sans-serif';">JOSE MANUEL VASQUEZ ARANGUREN</span>
							</strong>
						</p>
						<p class="MsoNormal" style="text-align: center; tab-stops: 85.0pt;" align="center">
							<strong>
								<span lang="ES-TRAD" style="font-family: 'Arial','sans-serif';">GOBERNADOR&nbsp; DEL ESTADO BOLIVARIANO DE GU&Aacute;RICO</span>
							</strong>
						</p>
					</td>
				</tr>
			</tbody>
		</table>
		</body>
		</html>

     `;
   }

   fillDesigInformatica(user?){
   if (user == undefined) user = this.user;
   this.code = 20;
   return this.content = `
    <!DOCTYPE html>
    <html>
      <head>
      </head>
        <body>
          <table style="border-collapse: collapse; width: 100%;" border="0" class="mceNonEditable">
          <tbody>
          <tr>
          <td style="width: 20%;"><img style="float: left;" src="${this.logo}" /></td>
          <td style="width: 60%; text-align: center; font-family:arial; font-size:13px; font-weight:bold;">${this.mem}</td>
          <td style="width: 20%;"><img style="float: right;" src="${this.escudo}" /></td>
          </tr>
          </tbody>
          </table>
        <div>
          <table style="border-collapse: collapse; width: 100%;" border="0">
          <tbody>
          <tr>
          <td style="width: 50%; font-family: arial;">
          <p style="font-size:12px;" class="mceNonEditable"><strong><!--correlativo--></strong></p>
          </td>
          <td style="width: 50%; font-family: arial;">
          <p style="font-size:12px; text-align:right;"><strong>San Juan de los Morros,</strong></p>
          <p style="font-size:12px; text-align:right;"><strong><!--date--></strong></p>
          </td>
          </tr>
          </tbody>
          </table>
        </div>  
        <div>
		    <table class="MsoTableGrid" style="border-collapse: collapse; mso-table-layout-alt: fixed; border: none; mso-border-alt: solid black .5pt; mso-border-themecolor: text1; mso-yfti-tbllook: 1184; mso-padding-alt: 0cm 5.4pt 0cm 5.4pt; width: 100%;"
		        border="1" width="100%" cellspacing="0" cellpadding="0">
		        <tbody>
		            <tr style="mso-yfti-irow: 0; mso-yfti-firstrow: yes; height: 11.5pt;">
		                <td style="width: 100; border: solid black 1.0pt; mso-border-themecolor: text1; mso-border-alt: solid black .5pt; padding: 0cm 5.4pt 0cm 5.4pt; height: 11.5pt;"
		                    colspan="6" rowspan="3" width="100%">
		                    <p class="MsoNormal" style="margin-bottom: .0001pt; text-align: center; line-height: normal;" align="center">
		                        <strong>
		                            <em>
		                                <span style="mso-bidi-font-size: 8.0pt; font-family: 'Arial','sans-serif'; color: #262626; mso-themecolor: text1; mso-themetint: 217;">DESIGNACI&Oacute;N ESPECIAL</span>
		                            </em>
		                        </strong>
		                    </p>
		                </td>
		                <!-- [if !supportMisalignedRows]-->
		                <td style="height: 11.5pt; border: none;" width="0" height="15">&nbsp;</td>
		                <!--[endif]-->
		            </tr>
		            <tr style="mso-yfti-irow: 1; height: 12.8pt;">
		                <!-- [if !supportMisalignedRows]-->
		                <td style="height: 12.8pt; border: none;" width="0" height="17">&nbsp;</td>
		                <!--[endif]-->
		            </tr>
		            <tr style="mso-yfti-irow: 2; height: 12.95pt;">
		                <!-- [if !supportMisalignedRows]-->
		                <td style="height: 12.95pt; border: none;" width="0" height="17">&nbsp;</td>
		                <!--[endif]-->
		            </tr>
		            <tr style="mso-yfti-irow: 3; height: 149.25pt;">
		                <td style="width: 100%; border: solid black 1.0pt; mso-border-themecolor: text1; border-top: none; mso-border-top-alt: solid black .5pt; mso-border-top-themecolor: text1; mso-border-alt: solid black .5pt; padding: 0cm 5.4pt 0cm 5.4pt; height: 149.25pt;"
		                    colspan="6" width="100%">
		                    <p class="MsoNormal" style="margin-bottom: .0001pt; text-align: justify; line-height: normal;">
		                        <span style="font-family: arial, helvetica, sans-serif;">
		                            <em>
		                                <span style="font-size: 9pt; color: #262626;">La Ciudadana
		                                    <strong>IVONNE KATIUSKA CAMACHO SEIJAS</strong>, venezolana, titular de la c&eacute;dula de identidad
		                                    N&deg;
		                                    <strong>V-11.118.146</strong>, actuando en el car&aacute;cter de Directora General de Inform&aacute;tica
		                                    de la Gobernaci&oacute;n del Estado Bolivariano de Gu&aacute;rico, seg&uacute;n consta
		                                    en el Decreto N&deg; 129 de fecha 16/05/17, publicada en Gaceta Extraordinaria N&deg;
		                                    155 de fecha 16 de mayo de 2017, en uso de las facultades legales conferidas en el ejercicio
		                                    de sus funciones, procede a otorgar
		                                    <strong>DESIGNACI&Oacute;N ESPECIAL</strong>, a los fines de efectuar en la [INGRESAR 
		                                    INSTITUCI&Oacute;N]: [ingresar actividad a realizar], esto a los
		                                    fines de contribuir al Fortalecimiento y operatividad de las redes tecnol&oacute;gicas
		                                    y telecomunicaciones, con todos sus privilegios, a trav&eacute;s de la actualizaci&oacute;n
		                                    e incorporaci&oacute;n de elementos, que permitan planificar, organizar, controlar, supervisar
		                                    y evaluar las actividades que generen las Distintas Dependencias Institucionales, ofreciendo
		                                    respuesta efectiva y eficaz, logrando implementar decisiones &oacute;ptimas por medio
		                                    de interconexiones, fundamentado as&iacute; los principios de honestidad, participaci&oacute;n,
		                                    celeridad, eficacia, eficiencia, transparencia y compromiso en el ejercicio de la funci&oacute;n
		                                    p&uacute;blica, conforme a los objetivos establecidos. Para el desarrollo de dicha actividad
		                                    se ha conformado un equipo de trabajo, integrado por los ciudadanos que se mencionan
		                                    a continuaci&oacute;n:</span>
		                            </em>
		                        </span>
		                    </p>
		                </td>
		                <!-- [if !supportMisalignedRows]-->
		                <td style="height: 149.25pt; border: none;" width="0" height="199">&nbsp;</td>
		                <!--[endif]-->
		            </tr>
		            <tr style="mso-yfti-irow: 4; height: 17.0pt;">
		                <td style="width: 25%; border: solid black 1.0pt; mso-border-themecolor: text1; border-top: none; mso-border-top-alt: solid black .5pt; mso-border-top-themecolor: text1; mso-border-alt: solid black .5pt; padding: 0cm 5.4pt 0cm 5.4pt; height: 17.0pt;"
		                    rowspan="3" width="25%">
		                    <p class="MsoNormal" style="text-align: center; line-height: normal; margin: 0cm 0cm .0001pt 2.55pt;" align="center">
		                        <span style="font-family: arial, helvetica, sans-serif;">
		                            <strong>
		                                <em>
		                                    <span style="font-size: 10.0pt; mso-bidi-font-size: 8.0pt; mso-bidi-font-family: 'Times New Roman';">Nombre(s) y Apellido(s), Cedula De Identidad,</span>
		                                </em>
		                            </strong>
		                        </span>
		                    </p>
		                    <p class="MsoNormal" style="text-align: center; line-height: normal; margin: 0cm 0cm .0001pt 2.55pt;" align="center">
		                        <span style="font-family: arial, helvetica, sans-serif;">
		                            <strong>
		                                <em>
		                                    <span style="font-size: 10.0pt; mso-bidi-font-size: 8.0pt; mso-bidi-font-family: 'Times New Roman';">Cargo Desempe&ntilde;ado:</span>
		                                </em>
		                            </strong>
		                        </span>
		                    </p>
		                </td>
		                <td style="width: 25%; border-top: none; border-left: none; border-bottom: solid black 1.0pt; mso-border-bottom-themecolor: text1; border-right: solid black 1.0pt; mso-border-right-themecolor: text1; mso-border-top-alt: solid black .5pt; mso-border-top-themecolor: text1; mso-border-left-alt: solid black .5pt; mso-border-left-themecolor: text1; mso-border-alt: solid black .5pt; mso-border-themecolor: text1; padding: 0cm 5.4pt 0cm 5.4pt; height: 17.0pt;"
		                    colspan="2" width="25%">
		                    <p class="MsoNormal" style="margin-bottom: .0001pt; text-align: center; line-height: normal;" align="center">&nbsp;</p>
		                </td>
		                <td style="width: 25%; border-top: none; border-left: none; border-bottom: solid black 1.0pt; mso-border-bottom-themecolor: text1; border-right: solid black 1.0pt; mso-border-right-themecolor: text1; mso-border-top-alt: solid black .5pt; mso-border-top-themecolor: text1; mso-border-left-alt: solid black .5pt; mso-border-left-themecolor: text1; mso-border-alt: solid black .5pt; mso-border-themecolor: text1; padding: 0cm 5.4pt 0cm 5.4pt; height: 17.0pt;"
		                    width="25%">
		                    <p class="MsoNormal" style="margin-bottom: .0001pt; text-align: center; line-height: normal;" align="center">
		                        <span style="font-family: arial, helvetica, sans-serif;">
		                            <em>
		                                <span style="font-size: 8pt; color: #262626;">C.I. V-&nbsp;</span>
		                            </em>
		                        </span>
		                    </p>
		                </td>
		                <td style="width: 5.0cm; border-top: none; border-left: none; border-bottom: solid black 1.0pt; mso-border-bottom-themecolor: text1; border-right: solid black 1.0pt; mso-border-right-themecolor: text1; mso-border-top-alt: solid black .5pt; mso-border-top-themecolor: text1; mso-border-left-alt: solid black .5pt; mso-border-left-themecolor: text1; mso-border-alt: solid black .5pt; mso-border-themecolor: text1; padding: 0cm 5.4pt 0cm 5.4pt; height: 17.0pt;"
		                    colspan="2" width="189">
		                    <p class="MsoNormal" style="margin-bottom: .0001pt; text-align: center; line-height: normal;" align="center">&nbsp;</p>
		                </td>
		                <!-- [if !supportMisalignedRows]-->
		                <td style="height: 17.0pt; border: none;" width="0" height="23">&nbsp;</td>
		                <!--[endif]-->
		            </tr>
		            <tr style="mso-yfti-irow: 5; height: 13.65pt;">
		                <td style="width: 25%; border-top: none; border-left: none; border-bottom: solid black 1.0pt; mso-border-bottom-themecolor: text1; border-right: solid black 1.0pt; mso-border-right-themecolor: text1; mso-border-top-alt: solid black .5pt; mso-border-top-themecolor: text1; mso-border-left-alt: solid black .5pt; mso-border-left-themecolor: text1; mso-border-alt: solid black .5pt; mso-border-themecolor: text1; padding: 0cm 5.4pt 0cm 5.4pt; height: 13.65pt;"
		                    colspan="2" width="25%">
		                    <p class="MsoNormal" style="margin-bottom: .0001pt; text-align: center; line-height: normal;" align="center">&nbsp;</p>
		                </td>
		                <td style="width: 25%; border-top: none; border-left: none; border-bottom: solid black 1.0pt; mso-border-bottom-themecolor: text1; border-right: solid black 1.0pt; mso-border-right-themecolor: text1; mso-border-top-alt: solid black .5pt; mso-border-top-themecolor: text1; mso-border-left-alt: solid black .5pt; mso-border-left-themecolor: text1; mso-border-alt: solid black .5pt; mso-border-themecolor: text1; padding: 0cm 5.4pt 0cm 5.4pt; height: 13.65pt;"
		                    width="25%">
		                    <p class="MsoNormal" style="margin-bottom: .0001pt; text-align: center; line-height: normal;" align="center">
		                        <span style="font-family: arial, helvetica, sans-serif;">
		                            <em>
		                                <span style="font-size: 8pt; color: #262626;">C.I. V-&nbsp;</span>
		                            </em>
		                        </span>
		                    </p>
		                </td>
		                <td style="width: 5.0cm; border-top: none; border-left: none; border-bottom: solid black 1.0pt; mso-border-bottom-themecolor: text1; border-right: solid black 1.0pt; mso-border-right-themecolor: text1; mso-border-top-alt: solid black .5pt; mso-border-top-themecolor: text1; mso-border-left-alt: solid black .5pt; mso-border-left-themecolor: text1; mso-border-alt: solid black .5pt; mso-border-themecolor: text1; padding: 0cm 5.4pt 0cm 5.4pt; height: 13.65pt;"
		                    colspan="2" width="189">
		                    <p class="MsoNormal" style="margin-bottom: .0001pt; text-align: center; line-height: normal;" align="center">&nbsp;</p>
		                </td>
		                <!-- [if !supportMisalignedRows]-->
		                <td style="height: 13.65pt; border: none;" width="0" height="18">&nbsp;</td>
		                <!--[endif]-->
		            </tr>
		            <tr style="mso-yfti-irow: 6; height: 13.95pt;">
		                <td style="width: 25%; border-top: none; border-left: none; border-bottom: solid black 1.0pt; mso-border-bottom-themecolor: text1; border-right: solid black 1.0pt; mso-border-right-themecolor: text1; mso-border-top-alt: solid black .5pt; mso-border-top-themecolor: text1; mso-border-left-alt: solid black .5pt; mso-border-left-themecolor: text1; mso-border-alt: solid black .5pt; mso-border-themecolor: text1; padding: 0cm 5.4pt 0cm 5.4pt; height: 13.95pt;"
		                    colspan="2" width="25%">
		                    <p class="MsoNormal" style="margin-bottom: .0001pt; text-align: center; line-height: normal;" align="center">&nbsp;</p>
		                </td>
		                <td style="width: 25%; border-top: none; border-left: none; border-bottom: solid black 1.0pt; mso-border-bottom-themecolor: text1; border-right: solid black 1.0pt; mso-border-right-themecolor: text1; mso-border-top-alt: solid black .5pt; mso-border-top-themecolor: text1; mso-border-left-alt: solid black .5pt; mso-border-left-themecolor: text1; mso-border-alt: solid black .5pt; mso-border-themecolor: text1; padding: 0cm 5.4pt 0cm 5.4pt; height: 13.95pt;"
		                    width="25%">
		                    <p class="MsoNormal" style="margin-bottom: .0001pt; text-align: center; line-height: normal;" align="center">
		                        <span style="font-family: arial, helvetica, sans-serif;">
		                            <em>
		                                <span style="font-size: 8pt; color: #262626;">C.I. V-&nbsp;</span>
		                            </em>
		                        </span>
		                    </p>
		                </td>
		                <td style="width: 5.0cm; border-top: none; border-left: none; border-bottom: solid black 1.0pt; mso-border-bottom-themecolor: text1; border-right: solid black 1.0pt; mso-border-right-themecolor: text1; mso-border-top-alt: solid black .5pt; mso-border-top-themecolor: text1; mso-border-left-alt: solid black .5pt; mso-border-left-themecolor: text1; mso-border-alt: solid black .5pt; mso-border-themecolor: text1; padding: 0cm 5.4pt 0cm 5.4pt; height: 13.95pt;"
		                    colspan="2" width="189">
		                    <p class="MsoNormal" style="margin-bottom: .0001pt; text-align: center; line-height: normal;" align="center">&nbsp;</p>
		                </td>
		                <!-- [if !supportMisalignedRows]-->
		                <td style="height: 13.95pt; border: none;" width="0" height="19">&nbsp;</td>
		                <!--[endif]-->
		            </tr>
		            <tr style="mso-yfti-irow: 7; height: 1.0cm;">
		                <td style="width: 25%; border: solid black 1.0pt; mso-border-themecolor: text1; border-top: none; mso-border-top-alt: solid black .5pt; mso-border-top-themecolor: text1; mso-border-alt: solid black .5pt; padding: 0cm 5.4pt 0cm 5.4pt; height: 1.0cm;"
		                    width="25%">
		                    <p class="MsoNormal" style="text-align: center; line-height: normal; margin: 0cm 0cm .0001pt 2.55pt;" align="center">
		                        <span style="font-family: arial, helvetica, sans-serif;">
		                            <strong>
		                                <em>
		                                    <span style="font-size: 10.0pt; mso-bidi-font-size: 7.0pt; mso-bidi-font-family: 'Times New Roman';">Objeto de la Designaci&oacute;n:</span>
		                                </em>
		                            </strong>
		                        </span>
		                    </p>
		                </td>
		                <td style="width: 75%; border-top: none; border-left: none; border-bottom: solid black 1.0pt; mso-border-bottom-themecolor: text1; border-right: solid black 1.0pt; mso-border-right-themecolor: text1; mso-border-top-alt: solid black .5pt; mso-border-top-themecolor: text1; mso-border-left-alt: solid black .5pt; mso-border-left-themecolor: text1; mso-border-alt: solid black .5pt; mso-border-themecolor: text1; padding: 0cm 5.4pt 0cm 5.4pt; height: 1.0cm;"
		                    colspan="5" width="75%">
		                    <p class="MsoNormal" style="margin-bottom: .0001pt; text-align: justify; line-height: normal;">&nbsp;</p>
		                </td>
		                <!-- [if !supportMisalignedRows]-->
		                <td style="height: 1.0cm; border: none;" width="0" height="38">&nbsp;</td>
		                <!--[endif]-->
		            </tr>
		            <tr style="mso-yfti-irow: 8; height: 21.95pt;">
		                <td style="width: 25%; border: solid black 1.0pt; mso-border-themecolor: text1; border-top: none; mso-border-top-alt: solid black .5pt; mso-border-top-themecolor: text1; mso-border-alt: solid black .5pt; padding: 0cm 5.4pt 0cm 5.4pt; height: 21.95pt;"
		                    width="25%">
		                    <p class="MsoNormal" style="text-align: center; line-height: normal; margin: 0cm 0cm .0001pt 2.55pt;" align="center">
		                        <span style="font-family: arial, helvetica, sans-serif;">
		                            <strong>
		                                <em>
		                                    <span style="font-size: 10pt;">Fecha de Inicio: </span>
		                                </em>
		                            </strong>
		                        </span>
		                    </p>
		                </td>
		                <td style="width: 25%; border-top: none; border-left: none; border-bottom: solid black 1.0pt; mso-border-bottom-themecolor: text1; border-right: solid black 1.0pt; mso-border-right-themecolor: text1; mso-border-top-alt: solid black .5pt; mso-border-top-themecolor: text1; mso-border-left-alt: solid black .5pt; mso-border-left-themecolor: text1; mso-border-alt: solid black .5pt; mso-border-themecolor: text1; padding: 0cm 5.4pt 0cm 5.4pt; height: 21.95pt;"
		                    width="25%">
		                    <p class="MsoNormal" style="text-align: center; line-height: normal; margin: 0cm 0cm .0001pt 2.55pt;" align="center">&nbsp;</p>
		                </td>
		                <td style="width: 25%; border-top: none; border-left: none; border-bottom: solid black 1.0pt; mso-border-bottom-themecolor: text1; border-right: solid black 1.0pt; mso-border-right-themecolor: text1; mso-border-top-alt: solid black .5pt; mso-border-top-themecolor: text1; mso-border-left-alt: solid black .5pt; mso-border-left-themecolor: text1; mso-border-alt: solid black .5pt; mso-border-themecolor: text1; padding: 0cm 5.4pt 0cm 5.4pt; height: 21.95pt;"
		                    colspan="3" width="25%">
		                    <p class="MsoNormal" style="margin-bottom: .0001pt; text-align: center; line-height: normal;" align="center">
		                        <span style="font-family: arial, helvetica, sans-serif;">
		                            <strong>
		                                <em>
		                                    <span style="font-size: 10pt;">Fecha de Culminaci&oacute;n:</span>
		                                </em>
		                            </strong>
		                        </span>
		                    </p>
		                </td>
		                <td style="width: 25%t; border-top: none; border-left: none; border-bottom: solid black 1.0pt; mso-border-bottom-themecolor: text1; border-right: solid black 1.0pt; mso-border-right-themecolor: text1; mso-border-top-alt: solid black .5pt; mso-border-top-themecolor: text1; mso-border-left-alt: solid black .5pt; mso-border-left-themecolor: text1; mso-border-alt: solid black .5pt; mso-border-themecolor: text1; padding: 0cm 5.4pt 0cm 5.4pt; height: 21.95pt;"
		                    width="25%">
		                    <p class="MsoNormal" style="margin-bottom: .0001pt; text-align: center; line-height: normal;" align="center">&nbsp;</p>
		                </td>
		                <!-- [if !supportMisalignedRows]-->
		                <td style="height: 21.95pt; border: none;" width="0" height="29">&nbsp;</td>
		                <!--[endif]-->
		            </tr>
		            <tr style="mso-yfti-irow: 9; mso-yfti-lastrow: yes; height: 76.9pt;">
		                <td style="width: 100%; border: solid black 1.0pt; mso-border-themecolor: text1; border-top: none; mso-border-top-alt: solid black .5pt; mso-border-top-themecolor: text1; mso-border-alt: solid black .5pt; padding: 0cm 5.4pt 0cm 5.4pt; height: 76.9pt;"
		                    colspan="6" width="100%">
		                    <p class="MsoNormal" style="margin-bottom: .0001pt; text-align: justify; line-height: normal;">
		                        <span style="font-family: arial, helvetica, sans-serif;">
		                            <em>
		                                <span style="font-size: 9pt; color: #262626;">Con esta designaci&oacute;n especial quedan revestidos de la cualidad necesaria en el marco
		                                    de las competencias para requerir informaci&oacute;n y efectuar las actividades relacionadas
		                                    con el objeto delimitado. Quedando as&iacute; otorgada la Designaci&oacute;n, no existiendo
		                                    un compromiso mayor que consolidar la gesti&oacute;n del Ejecutivo Regional en perfecta
		                                    conjunci&oacute;n con los m&aacute;s altos valores y principios que rigen el actuar de
		                                    la Administraci&oacute;n P&uacute;blica, es por lo que se requiere la efectiva comunicaci&oacute;n,
		                                    interacci&oacute;n y la mayor colaboraci&oacute;n en procura de una &oacute;ptima eficiencia
		                                    y eficacia de la Gesti&oacute;n.</span>
		                            </em>
		                        </span>
		                    </p>
		                </td>
		                <!-- [if !supportMisalignedRows]-->
		                <td style="height: 76.9pt; border: none;" width="0" height="103">&nbsp;</td>
		                <!--[endif]-->
		            </tr>
		        </tbody>
		    </table>
		</div> 
        <div>
          <table style="border-collapse: collapse; width: 100%; border="0" >
          <tbody>
          <tr>
          <td style="width: 33.3%; font-family: arial;">
            <!--seal-->
          </td>
          <td style="width: 33.3%; font-family: arial; text-align:center;" class="mceNonEditable">
            <!--sign-->
            <!--gaceta-->
          </td>
          <td align="right" style="width: 33.3%; font-family: arial;" class="mceNonEditable">
            <!--qrcode-->
          </td>
          </tr>
          </tbody>
          </table>
        </div>
      </body>
    </html>
    `;

  }

	upperCase(w) {
		return w.toUpperCase();
	}

	ngOnInit() {
	}
	content;
}