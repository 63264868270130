import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OficioComponent } from '../../documents/oficio/oficio.component';
import { PcComponent } from '../../documents/pc/pc.component';
import { DecretoComponent } from '../../documents/decreto/decreto.component';
import { MatBottomSheet, MatBottomSheetRef } from '@angular/material';
import { FormatSheetComponent } from './format-sheet/format-sheet.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { SendToComponent, OPERATION_TYPE } from './send-to/send-to.component';
import { UploadComponent } from './upload/upload.component';
import { SwalComponent } from '@toverux/ngx-sweetalert2';
import { MailService } from '../../services/mail/mail.service';

@Component({
  selector: 'app-write',
  templateUrl: './write.component.html',
  styleUrls: ['./write.component.scss']
})
export class WriteComponent implements OnInit {

  defaultContent: string;
  inline: boolean = false;
  content: string = ``;
  asunto: string;
  doctype: number;
  loading: boolean = true;
  attachments: any[] = [];
  redactor: boolean = false;
  photo: any;
  lastImage: string;
  asOther: boolean = false;
  code: number = 1;
  editing: boolean = false;
  editableContent: any;
  private editorId = "";

  private aprobador;

  constructor(
    private bottomSheet: MatBottomSheet,
    public dialog: MatDialog,
    private router: Router,
    private mailService: MailService
  ) {
    this.redactor = JSON.parse(localStorage.getItem("user")).isRedactor;
  }

  editorReady(e) {
    this.loading = false;
    this.editorId = e.event.target.id;
    //document.getElementById(this.editorId).style.width = '816px';
    document.getElementsByTagName('html')[0].style.overflowY = 'hidden';
    document.getElementsByTagName('body')[0].style.overflowY = 'hidden';
    if (!this.editing && !JSON.parse(localStorage.getItem('user')).isAprobador)
      this.switchRedactor();
  }

  calcPages() {
    const editor = document.getElementById(this.editorId);
    const letter = Math.trunc(editor.offsetHeight / 1056);
    const legal = Math.trunc(editor.offsetHeight / 1344);
    return { letter: letter, legal: legal };
  }

  switchRedactor() {
    this.asOther = !this.asOther;
    if (this.asOther) {
      this.aprobador = JSON.parse(localStorage.getItem("user")).aprobador;
      this.content, this.defaultContent = this.setFormat(this.code, this.aprobador);
      this.content = this.defaultContent;
      this.loadCreds();
    } else {
      this.content, this.defaultContent = this.setFormat(this.code, JSON.parse(localStorage.getItem('user')));
      this.content = this.defaultContent;
      this.loadCreds();
    }
  }

  loadCreds() {

    let sign = "";
    let sgsign = "";
    let seal = "";
    let gaceta = "";
    let cargo = "";
    let name = "";
    let sgseal = "";
    let u = JSON.parse(localStorage.getItem("user"));

    if (this.asOther) {
      gaceta = this.aprobador.gaceta || '';
      cargo = this.aprobador.charge_h;
      name = this.aprobador.firstname + ' ' + this.aprobador.lastname;
      sign = this.aprobador.sign;
      seal = this.aprobador.seal;
    } else {
      cargo = u.charge_h || '';
      gaceta = u.gaceta || '';
      sign = localStorage.getItem('sign');
      seal = localStorage.getItem('seal');
      name = u.firstname + ' ' + u.lastname;
    }

    const swidth = this.code == 6 || this.code == 7 ? '180px' : '350px';
    const sealwidth = this.code == 6 || this.code == 7 ? '100px' : '150px';

    if (this.code != 6) {
      this.defaultContent = this.defaultContent.replace('<!--sign-->', '<img width="' + swidth + '"; style="max-height:250px;" src=' + sign + '></img>');
      this.defaultContent = this.defaultContent.replace('<!--seal-->', '<img width="' + sealwidth + '"; src=' + seal + '></img>');
      this.defaultContent = this.defaultContent.replace('<!--gaceta-->', '<p style="text-align:center; font-size:12px; font-family:arial; font-weight:bold;">' + name.toUpperCase() + '<br>' + cargo.toUpperCase() + '<br>' + gaceta + '</p>');
    }

    if (this.code == 11) this.defaultContent = this.defaultContent.replace('<!--sg-sign-->', '<img width="350px"; style="max-height:250px;" src=' + sgsign + '></img>');;
    this.content = this.defaultContent;

  }

  loadImage(e) {
    var file = e.target.files[0];
    if (file.size > 150000) { new SwalComponent({ title: '<h5 style="font-family:arial;">Esta imagen es muy pesada.<h5>', html: '<p style="font-family:arial;">La imagen debe pesar menos de <strong>150 kilobytes<strong>.<p>', type: 'info' }).show(); return; };
    if (file == undefined) return;
    var reader = new FileReader();
    reader.onloadend = () => {
      this.content = this.content.replace(this.lastImage || '<!--fotoIngreso-->', '<img style="width:150px;" src="' + reader.result.toString() + '"></img>');
      this.defaultContent = this.content;
      this.lastImage = '<img style="width:150px;" src="' + reader.result.toString() + '"></img>';
    };
    reader.readAsDataURL(file);
  }

  keyUpEditor(e) {
    console.log(this.calcPages());
  }

  openSendDialog() {

    let d = this.dialog.open(SendToComponent, {
      width: '800px',
      minHeight: '623.3px',
      data: {
        data: this.content,
        subject: this.asunto,
        attachments: this.attachments,
        status: this.asOther ? 2 : 1,
        maxReceivers: -1,
        code: this.doctype,
        operation: OPERATION_TYPE.SEND,
        sender: this.asOther ? this.aprobador.id : JSON.parse(localStorage.getItem('user')).id,
        pass: this.asOther
      }
    });
    d.afterClosed()
      .subscribe(code => {
        let swal = new SwalComponent({
          type: 'success',
          text: 'Mensaje enviado.',
          timer: 1500
        });
        if (code) {
          this.router.navigate(['/bandeja/enviadas']);
          swal.show();
        }

      });
  }

  openUploadSheet() {
    let upload = this.bottomSheet.open(UploadComponent, {
      disableClose: true,
      data: (this.attachments.length > 0) ? this.attachments : null
    });
    upload.afterDismissed().subscribe(res => {
      this.attachments = res;
    });
  }

  openFormats() {
    let bottomSheet = this.bottomSheet.open(FormatSheetComponent);
    bottomSheet.afterDismissed().subscribe(result => {
      if (!result) return;
      this.code = result;
      let u = this.asOther ? this.aprobador : JSON.parse(localStorage.getItem('user'));
      this.content, this.defaultContent = this.setFormat(result, u);
      this.content = this.defaultContent;
      this.loadCreds();
    }, error => {
      console.log(error);
    });
  }

  src() {
    console.log(this.content);
  }

  update() {
    this.mailService.update({ id: this.editableContent.id, data: this.content })
      .subscribe((res: { success: boolean }) => {
        if (res.success) {
          new SwalComponent({ type: 'info', toast: true, html: '<p>Guardado.</p>', position: 'top-right', timer: 1500 }).show();
        } else {
          new SwalComponent({ type: 'info', toast: true, html: '<p>No se ha podido guardar. Intente nuevamente.</p>', position: 'top-right', timer: 1500 }).show();
        }
      }, error => {
        console.log(error);
      });
  }

  ngOnInit() {

    this.editing = (window.location.pathname == '/editar');
    if (!this.editing) {
      let doc = new OficioComponent();
      this.content, this.defaultContent = doc.fillOficio();
      this.content = this.defaultContent;
      this.doctype = doc.code;
      this.loadCreds();
    } else {
      let toEdit = JSON.parse(localStorage.getItem('currentEditingDoc'));
      this.editableContent = toEdit;
      this.content, this.defaultContent = toEdit.data;
      this.asunto = toEdit.subject;
      this.content = this.defaultContent;
    }

  }

  setFormat(code, user?) {
    user = user || JSON.parse(localStorage.getItem('user'));
    let data;
    let doc;
    switch (code) {
      case 1:
        doc = new OficioComponent();
        data = doc.fillOficio(user);
        break;
      case 2:
        doc = new PcComponent();
        data = doc.fillPc(user);
        break;
      case 3:
        doc = new PcComponent();
        data = doc.fillPcInfo(3, 'PUNTO DE CUENTA INFORMATIVO', 'ASUNTO', 'ARGUMENTACIÓN', 'PROPUESTA', user);
        break;
      case 4:
        doc = new PcComponent();
        data = doc.fillPcIngreso(user);
        break;
      case 5:
        doc = new PcComponent();
        data = doc.fillPcEgreso(user);
        break;
      case 6:
        doc = new DecretoComponent();
        data = doc.fillDecreto(user);
        break;
      case 7:
        doc = new DecretoComponent();
        data = doc.fillDesignacion(user);
        break;
      case 8:
        doc = new DecretoComponent();
        data = doc.fillErratas(user);
        break;
      case 9:
        doc = new DecretoComponent();
        data = doc.fillResolucion(user);
        break;
      case 10:
        doc = new OficioComponent();
        data = doc.fillReporteInfo(user);
        break;
      case 11:
        doc = new DecretoComponent();
        data = doc.fillDecretoOficio(user);
        break;
      case 12:
        doc = new OficioComponent();
        data = doc.fillPresupuestaria(user);
        break;
      case 13:
        doc = new DecretoComponent();
        data = doc.fillConstanciaUso(user);
        break;
      case 14:
        doc = new DecretoComponent();
        data = doc.fillConstanciaUso(user, 14, 'ACTO MOTIVADO');
        break;
      case 15:
        doc = new OficioComponent();
        data = doc.fillAdjudicacion(user);
        break;
      case 16:
        doc = new DecretoComponent();
        data = doc.fillDocumentoConsultoria(user);
        break;
      case 17:
        doc = new OficioComponent();
        data = doc.fillOficioGob(user);
        break;
      case 18:
        doc = new PcComponent();
        data = doc.fillPcInfo(18, 'DECLARACIÓN BAJO FE DE JURAMENTO', 'DECLARACIÓN', 'PROYECTO', 'INDENTIFICACIÓN DE LA EMPRESA', user);
        break;
      case 19:
        doc = new PcComponent();
        data = doc.fillPcInfo(19, 'PUNTO DE CUENTA PARA AUTORIZAR FORMATO DE OBRAS', 'ASUNTO', 'OBRA', 'MONTO PARTIDAS', user);
        break;
      case 20:
        doc = new DecretoComponent();
        data = doc.fillDesigInformatica(user);
        break;
      default:
        doc = new OficioComponent();
        data = doc.fillOficio(user);
        break;
    }
    this.doctype = doc.code;
    return data;
  }

  setInline() {
    this.inline = !this.inline;
  }

  reset() {
    this.content = this.defaultContent;
  }

}