import { Injectable, Output, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { URL } from '../const';
import { ResponseContentType } from '@angular/http';

@Injectable({
  providedIn: 'root'
})
export class MailService {

  private token: string;
  private authHeaders: HttpHeaders;
  @Output() notReaded: EventEmitter<any> = new EventEmitter();


  constructor(private http: HttpClient) { }

  //Aprobar correspondencia.
  public aprobar(data: { mail: string, data: any }) {
    let _headers = this.setHeader();
    return this.http.post(URL + '/mail/aprobar', data, {
      headers: _headers
    });
  }

  public notReadedMails(value) {
    this.notReaded.emit(value);
  }

  public sendToInfo(mail: number | string) {
    return this.http.get(URL + '/mail/info/' + mail, {
      headers: this.setHeader()
    });
  }

  public setBorrador(data: { data: string, sender: number, mail_type: number, attached: number[] }) {
    let _headers = this.setHeader();
    return this.http.post(URL + '/mail/borrador', data, {
      headers: _headers
    });
  }

  public update(mail: { id: number, data: any }) {
    let _headers = this.setHeader();
    return this.http.put(URL + '/mail/update', mail, { headers: _headers });
  }

  public revoke(id) {
    let _headers = this.setHeader();
    return this.http.get(URL + '/mail/revoke/' + id, { headers: _headers });
  }

  //Asignar correspondencia.
  public assignTo(data: { assigned_to: number, mail_id: number, task: string }) {
    let _headers = this.setHeader();
    return this.http.post(URL + '/mail/assign', data, { headers: _headers });
  }

  //Descargar adjunto.
  public download(attachment): Observable<ArrayBuffer> {
    let _headers = this.setHeader();
    return this.http.post(URL + '/download', {
      attachment: attachment
    }, {
        headers: _headers,
        responseType: 'arraybuffer'
      });
  }

  public resendMail(data: { mail_id: number, receivers: number[] }) {
    let _headers = this.setHeader();
    return this.http.post(URL + '/mail/resend', data, {
      headers: _headers
    });
  }

  //Enviar correspondencia.
  public sendMail(data: { mail_type: number, data: any, subject: string, status: number, receivers: Array<number>, attachments: Array<number>, sender: any }) {
    let _headers = this.setHeader();
    return this.http.post(URL + '/mail/send', data, {
      headers: _headers
    });
  }

  //Leer correspondencia.
  public getMail(id) {
    let extra = "";
    if (id == 'ref') {
      extra = '/ref';
      id = localStorage.getItem('currentReference');
    }
    let _headers = this.setHeader();
    return this.http.get(URL + '/mail/read/' + id + extra, {
      headers: _headers
    });
  }

  //Leer bandeja.
  public getTray(type: string) {
    let _headers = this.setHeader();
    return this.http.get(URL + '/mail/tray/' + type, {
      headers: _headers
    });
  }

  //Leer destinatarios.
  public getReceivers(pass) {
    pass = pass ? 1 : 0;
    let _headers = this.setHeader();
    return this.http.get(URL + '/receivers/' + pass, {
      headers: _headers
    });
  }

  //Archivar correspondencia.
  public archive(mails: string[], action: number = 1) {
    let _headers = this.setHeader();
    return this.http.post(URL + '/mail/archive', { archive: mails, action: action }, {
      headers: _headers
    });
  }

  //Diferir
  public diff(data: { id: number, differ: any }) {
    let _headers = this.setHeader();
    return this.http.post(URL + '/mail/differ', data, {
      headers: _headers
    });
  }

  //Rechazar
  public reject(data: { id: number, data: any }) {
    let _headers = this.setHeader();
    return this.http.post(URL + '/mail/reject', data, {
      headers: _headers
    });
  }

  //Responder asignacion.
  public respondAssign(data: { id: number, response: any }) {
    let _headers = this.setHeader();
    return this.http.post(URL + '/mail/respond-assign', data, {
      headers: _headers
    });
  }

  public mailOut(id: number) {
    let _headers = this.setHeader();
    return this.http.post(URL + '/mail/out', { id: id }, {
      headers: _headers
    });
  }

  public makePdf(mail: any): Observable<ArrayBuffer> {
    let _headers = this.setHeader();
    let data = { data: mail.data, correlativo: mail.correlativo };
    return this.http.post(URL + '/mail/pdf', data, {
      headers: _headers,
      responseType: 'arraybuffer'
    });
  }

  public sgCreds() {
    let _headers = this.setHeader();
    return this.http.get(URL + '/mail/creds/sg', {
      headers: _headers
    });
  }

  public sgSeal() {
    let _headers = this.setHeader();
    return this.http.get(URL + '/mail/seal/sg', {
      headers: _headers
    });
  }

  //Setear request headers.
  private setHeader(): HttpHeaders {

    let token = localStorage.getItem('token');
    let authHeaders;
    if (token) {
      return authHeaders = new HttpHeaders({
        'Authorization': 'Bearer ' + token
      });
    }

    return new HttpHeaders({});

  }

}
