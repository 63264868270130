import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import {MatToolbarModule} from '@angular/material/toolbar';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatInputModule} from '@angular/material/input';
import {MatIconModule} from '@angular/material/icon';
import {LayoutModule} from '@angular/cdk/layout';
import {MatListModule} from '@angular/material/list';
import {MatDividerModule} from '@angular/material/divider';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatButtonModule} from '@angular/material/button';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatStepperModule} from '@angular/material/stepper';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatSelectModule} from '@angular/material/select';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatCardModule} from '@angular/material/card';
import {MatMenuModule} from '@angular/material/menu';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatBottomSheetModule} from '@angular/material/bottom-sheet';
import {MatChipsModule} from '@angular/material/chips';
import {MatDialogModule} from '@angular/material/dialog';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatTabsModule} from '@angular/material/tabs';
import {MatDatepickerModule} from '@angular/material/datepicker';

@NgModule({
  imports: [
   CommonModule,
   MatToolbarModule,
   MatSidenavModule,
   MatInputModule,
   MatIconModule,
   LayoutModule,
   MatListModule,
   MatDividerModule,
   MatExpansionModule,
   MatButtonModule,
   MatTooltipModule,
   MatFormFieldModule,
   MatStepperModule,
   MatGridListModule,
   MatSelectModule,
   MatAutocompleteModule,
   MatAutocompleteModule,  
   MatCardModule,
   MatMenuModule,
   MatCheckboxModule,
   MatBottomSheetModule,
   MatChipsModule,
   MatDialogModule,
   MatProgressSpinnerModule,
   MatProgressBarModule,
   MatSnackBarModule,
   MatTabsModule,
   MatDatepickerModule
   ],
  exports: [
   MatToolbarModule,
   MatSidenavModule,
   MatInputModule,
   MatIconModule,
   LayoutModule,
   MatListModule,
   MatDividerModule,
   MatExpansionModule,
   MatButtonModule,
   MatTooltipModule,
   MatFormFieldModule,
   MatStepperModule,
   MatGridListModule,
   MatSelectModule,
   MatAutocompleteModule,
   MatCardModule,
   MatMenuModule,
   MatCheckboxModule,
   MatBottomSheetModule,
   MatChipsModule,
   MatDialogModule,
   MatProgressSpinnerModule,
   MatProgressBarModule,
   MatSnackBarModule,
   MatTabsModule,
   MatDatepickerModule
  ],  
  declarations: [],
  providers: []
})
export class MaterialModule { }
